import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FiAlertOctagon } from 'react-icons/fi';
import { MdInfoOutline } from 'react-icons/md';

import Modal from '../../../../organisms/modal/Modal';
import { AugurSettingsElementProps } from '../../../../pages/augur/type';
import ErrorBoundary from '../../../../pages/error-boundary/ErrorBoundary';
import commonParentStyles from '../../common/styles.module.scss';
import { AugurSettingsElementMeta } from '../types/meta';

export type Props = {
  elementProps: AugurSettingsElementProps;
  elementMeta: AugurSettingsElementMeta;
  /** Flag for to disable any interactive functionality and to decide whether to initializing element as form field. */
  interactionDisabled?: boolean;
};

const SettingsParentElement: FC<Props> = (props) => {
  const { elementProps, elementMeta, interactionDisabled = false } = props;
  const { uuid, title, description, config, defaultAugurSettings } =
    elementProps;
  const { Component, validationFunction } = elementMeta;

  const [showModal, setModalShown] = useState(false);

  const [layoutEditorElement, setLayoutEditorElement] = useState<HTMLElement>();
  // get portal target after rendering is finished
  useEffect(() => {
    setLayoutEditorElement(
      document.getElementById('layoutEditorContainer') ?? undefined
    );
  }, []);

  function renderHeader(error?: string) {
    return (
      <div className={commonParentStyles.headerContainer}>
        {title && (
          <div className={commonParentStyles.title} title={title}>
            {title}
          </div>
        )}
        {description && (
          <MdInfoOutline
            size={17}
            className={commonParentStyles.infoIcon}
            title={description}
            onClick={() => !interactionDisabled && setModalShown(true)}
          />
        )}
        {!!error && (
          <div className={commonParentStyles.fullScreen} title={error}>
            <FiAlertOctagon size={18} color={'red'} />
          </div>
        )}
        {showModal && (
          <Modal
            show={showModal}
            hideModal={() => setModalShown(false)}
            headline={{
              id: 'no-id',
              defaultMessage: 'Information',
            }}
            buttons={[
              {
                color: 'white',
                onClick: () => {
                  setModalShown(false);
                },
                label: 'Ok',
              },
            ]}
          >
            <span>{description}</span>
          </Modal>
        )}
      </div>
    );
  }

  function renderDefaultComponent() {
    return (
      <>
        {renderHeader()}
        <ErrorBoundary fullScreenHeight={false}>
          <div
            className={commonParentStyles.content}
            style={{ pointerEvents: interactionDisabled ? 'none' : 'auto' }}
          >
            <elementMeta.Component
              value={defaultAugurSettings}
              readOnly={true}
              disabled={true}
              portalTarget={layoutEditorElement}
              config={config}
            />
          </div>
        </ErrorBoundary>
      </>
    );
  }

  function renderControlledComponent() {
    return (
      <Controller
        name={`settingsData.${uuid}`}
        render={({ field, fieldState }) => {
          const { ref, ...rest } = field; // extract ref to pass as inputRef
          return (
            <>
              {renderHeader(fieldState.error?.message)}
              <ErrorBoundary fullScreenHeight={false}>
                <div
                  className={commonParentStyles.content}
                  style={{
                    pointerEvents: interactionDisabled ? 'none' : 'auto',
                  }}
                >
                  <Component
                    {...rest}
                    {...fieldState}
                    inputRef={ref}
                    portalTarget={layoutEditorElement}
                    config={config}
                    error={validationFunction?.(rest.value, config)}
                  />
                </div>
              </ErrorBoundary>
            </>
          );
        }}
      />
    );
  }

  function renderContainer() {
    return (
      <div className={commonParentStyles.parentContainer}  data-testid={`parentContainer_${title}`}>
        {interactionDisabled
          ? renderDefaultComponent()
          : renderControlledComponent()}
      </div>
    );
  }

  return renderContainer();
};

export default SettingsParentElement;
