import { Parameter, ValueType } from 'common/dist/types/module.nbc';
import * as React from 'react';

import styles from './styles.module.scss';
import {
  ParameterTuningAugurSettings,
  ParameterTuningValidationError,
} from './type';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';

export type Props = {
  algorithmName: string;
  parameter: Parameter;
  value: ParameterTuningAugurSettings['algorithms'][number]['parameters'][number];
  onChange: (
    val: ParameterTuningAugurSettings['algorithms'][number]['parameters'][number]
  ) => void;
  isTouched: boolean;
  error?: ParameterTuningValidationError;
};

function ValueTypeToSpeaking(valueType: ValueType) {
  switch (valueType.type) {
    case 'double':
      return `a real number between ${valueType.min} and ${valueType.max}`;
    case 'int':
      return `an integer between ${valueType.min} and ${valueType.max}`;
  }
}

const ParameterTuningParameter: React.FC<Props> = ({
  algorithmName,
  isTouched,
  onChange,
  parameter,
  value,
  error,
}) => {
  return (
    <div className={styles.gridItem}>
      <h4 className={styles.headline}>{parameter.speakingName}</h4>
      <p className={styles.description}>{parameter.description}</p>
      <p>
        Enter a list (separated by ,), where each entry must be{' '}
        {parameter.validValues
          .map((vt) => ValueTypeToSpeaking(vt))
          .join(' or ')}
        .
      </p>

      <div className={styles.input}>
        {!error?.algorithms?.[algorithmName]?.[parameter.name] && (
          <div className={styles.placeholder}></div>
        )}

        <IntlTextInputLine
          isTouched={isTouched}
          error={error?.algorithms?.[algorithmName]?.[parameter.name]}
          placeholder={' '}
          value={value.values.join(', ')}
          onChange={(e) => {
            const valuesString = e.target.value as string;
            const values = valuesString
              .split(',')
              .map((x) => x.trim())
              .map((x) => parseFloat(x));
            onChange?.({
              name: parameter.name,
              values,
            });
          }}
        />
      </div>
    </div>
  );
};

export default ParameterTuningParameter;
