import { JOB_STATUS } from 'common/dist/constants/enums';
import React, { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { useSelectedAugurPage } from './hooks';
import styles from './styles.module.scss';
import { AugurSettingsWithAugurProperties, ModuleConfiguration } from './type';
import ViewAugur from './ViewAugur';
import {
  useAugur,
  useAugurCodeExistsCheck,
  useSettings,
  useUpdateAugur,
  useUpdateSettings,
} from '../../../core/api/augurs';
import { useInfiniteJobs, useJobForModel } from '../../../core/api/jobs';
import { useActiveModel } from '../../../core/api/mlModels';
import { useModules } from '../../../core/api/modules';
import { useReportsByCode } from '../../../core/api/reports';
import { event } from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import { AugurDetailsRouteParams } from '../../index/routes';
import OverlayAugurDetails from '../../organisms/sub-header/overlay-augur-details/OverlayAugurDetails.container';
import {
  getFirstActiveJobCodeOfCurrentCategory,
  getTimeTravelEntryFromJob,
} from '../../organisms/time-travel/helpers';
import { useTimeTravelHelpers } from '../../organisms/time-travel/hooks';
import MainContainer from '../main-container/MainContainer';
import NotFound from '../not-found/NotFound.container';

/**
 * Responsible for fetching jobs and filtering them for currently displayed reports.
 */
export const LiveAugur: FC = () => {
  const pathPrefix = useRouteMatch().url;
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augurCodeExists, isLoading: isAugurCheckLoading } =
    useAugurCodeExistsCheck(augurCode);

  const { selectedPageCategory } = useSelectedAugurPage(pathPrefix);

  const { isHighlighted } = useTimeTravelHelpers(selectedPageCategory);

  const { data: activeModel, isLoading: isActiveModelLoading } = useActiveModel(
    habitatCode,
    augurCode,
    augurCodeExists
  );
  const { data: jobsData, isLoading: isJobsLoading } = useInfiniteJobs(
    habitatCode,
    augurCode,
    JOB_STATUS.FINISHED,
    augurCodeExists
  );
  const { data: additionalJobs } = useJobForModel(
    habitatCode,
    augurCode,
    JOB_STATUS.FINISHED,
    selectedPageCategory,
    activeModel?.code
  );

  if (augurCodeExists === false && !isAugurCheckLoading) {
    return <NotFound fullViewHeight />;
  }
  if (isAugurCheckLoading || isActiveModelLoading || isJobsLoading) {
    return <Busy isBusy={true} />;
  }

  const jobs = jobsData ? jobsData.pages.flatMap((page) => page.jobs) : [];
  if (additionalJobs && Array.isArray(additionalJobs)) {
    additionalJobs.forEach((additionalJob) => {
      if (!jobs.some((job) => job.code === additionalJob.code)) {
        jobs.push(additionalJob);
      }
    });
  }
  // calculate the reports to be displayed from the TimeTravel selection
  // this uses the same logic that is used for calculating the highlighted entries of the TimeTravel component
  const firstActiveJobCodeOfCurrentCategory =
    getFirstActiveJobCodeOfCurrentCategory(
      jobs.map(getTimeTravelEntryFromJob),
      selectedPageCategory,
      activeModel.code
    )?.code;
  const displayedJobs = jobs.filter((job) =>
    isHighlighted(job.code, job.type, firstActiveJobCodeOfCurrentCategory)
  );

  return (
    <MainContainer
      fullWidth
      transparent
      additionalInnerClassname={styles.augurDetailsInner}
    >
      <OverlayAugurDetails />
      <InnerLiveAugur
        displayedReportCodes={displayedJobs.map((job) => job.reportCode)}
        pathPrefix={pathPrefix}
        hasNoModel={!activeModel.code}
      />
    </MainContainer>
  );
};

export type InnerProps = {
  displayedReportCodes: string[];
  pathPrefix: string;
  hasNoModel: boolean;
};

/**
 * Responsible for fetching the data for the ViewAugur.
 * @param displayedReportCodes
 * @param pathPrefix
 * @constructor
 */
export const InnerLiveAugur: FC<InnerProps> = ({
  displayedReportCodes,
  pathPrefix,
  hasNoModel,
}) => {
  const dispatch = useAppDispatch();
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isLoading: isAugurLoading } = useAugur(
    habitatCode,
    augurCode
  );
  const { mutate: mutateAugur } = useUpdateAugur(habitatCode, augurCode);
  // separate hook because of loading animation
  const { mutate: mutateArchiveAugur, isLoading: isArchiveAugurLoading } =
    useUpdateAugur(habitatCode, augurCode);

  const { data: augurSettings, isLoading: isAugurSettingsLoading } =
    useSettings(habitatCode, augurCode);
  const { mutate: mutateAugurSettings } = useUpdateSettings(
    habitatCode,
    augurCode
  );
  const {
    data: augurReportsData,
    isInitialLoading: isAugurReportsLoading,
    // fetch status is necessary because of dependant query
    fetchStatus: augurReportsFetchStatus,
  } = useReportsByCode(habitatCode, augurCode, displayedReportCodes);
  const augurReports = displayedReportCodes.length > 0 ? augurReportsData : [];

  const { data: modules, isInitialLoading: isModulesLoading } = useModules();

  const getModuleConfig = (moduleCode?: string, moduleVersionCode?: string) => {
    return modules
      ?.find((module) => module.code === moduleCode)
      ?.versions?.find((version) => version.code === moduleVersionCode)
      ?.config as unknown as ModuleConfiguration;
  };

  if (
    isAugurLoading ||
    isAugurSettingsLoading ||
    (isAugurReportsLoading && augurReportsFetchStatus === 'fetching') ||
    isModulesLoading
  )
    return <Busy isBusy={true} />;

  const displayedAugurSettings: AugurSettingsWithAugurProperties = {
    general: {
      augurName: augur.name,
      module: {
        moduleCode: augur.moduleVersion.module.code,
        moduleVersionCode: augur.moduleVersion.code,
      },
      attributes: augur.attributes,
      resources: augurSettings.resources,
    },
    settingsData: augurSettings.settingsData,
  };

  // @ts-expect-error because the frontend type is more complex and uses frontend specific types, typescript isn't quite happy here
  const config: ModuleConfiguration = augur.moduleVersion.config;

  return (
    <ViewAugur
      moduleConfig={config}
      getModuleConfig={getModuleConfig}
      augurReports={augurReports}
      augurSettings={displayedAugurSettings}
      pathPrefix={pathPrefix}
      isDevMode={false}
      onSubmitAugurSettings={(settings) => {
        const newModuleVersion = settings.general.module.moduleVersionCode;
        mutateAugur({
          name: settings.general.augurName,
          attributes: settings.general.attributes,
        });
        mutateAugurSettings({
          settingsData: settings.settingsData,
          resources: settings.general.resources,
          moduleVersionCode: newModuleVersion,
          merge: false,
        });
      }}
      onArchiveAugur={(archive) => {
        mutateArchiveAugur(
          {
            archived: archive,
          },
          {
            onSuccess: () => {
              dispatch(
                sendNotification(
                  archive
                    ? 'notifications.title.augur_archived'
                    : 'notifications.title.augur_restored',
                  // @ts-ignore
                  archive
                    ? 'notifications.description.augur_has_been_archived'
                    : 'notifications.description.augur_has_been_restored',
                  event,
                  { augurCode, augurName: augur.name }
                )
              );
            },
          }
        );
      }}
      augurArchived={augur.archived}
      isArchiveButtonLoading={isArchiveAugurLoading}
      hasNoModel={hasNoModel}
    />
  );
};

export default LiveAugur;
