import * as React from 'react';

import ParameterTuningAlgorithm from './ParameterTuningAlgorithm';
import styles from './styles.module.scss';
import {
  ParameterTuningAugurSettings,
  ParameterTuningConfig,
  ParameterTuningValidationError,
} from './type';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { AugurSettingsProps } from '../../types/meta';

type OptionType = { label: string; value: string };

export type Props = AugurSettingsProps<
  ParameterTuningAugurSettings,
  ParameterTuningConfig,
  ParameterTuningValidationError
>;

export type ParameterTuningErrorType = {
  global: string;
  algorithms: {
    [name: string]: {
      [parameter: string]: string;
    };
  };
};

const ParameterTuning: React.FC<Props> = ({
  onChange,
  onBlur,
  value = {
    algorithms: [],
    searchStrategy: { name: '' },
  },
  config,
  error,
}) => {
  const { parameterTuningSchema } = config;

  const options: OptionType[] | undefined =
    parameterTuningSchema?.availableSearchStrategies.map(
      ({ name, speakingName }) => ({
        label: speakingName,
        value: name,
      })
    );

  function renderSelectableAlgorithms() {
    return (
      <>
        {parameterTuningSchema &&
          parameterTuningSchema.availableAlgorithms.map(
            (availableAlgorithm) => {
              const algorithmValue = value.algorithms.find(
                (algorithm) => algorithm.name === availableAlgorithm.name
              );
              return (
                <ParameterTuningAlgorithm
                  key={`${availableAlgorithm.name}`}
                  availableAlgorithm={availableAlgorithm}
                  value={algorithmValue}
                  onChange={(algorithmValue) => {
                    // if algorithmValue is undefined it was deselected
                    const filteredAlgorithms = value.algorithms.filter(
                      (algorithm) => algorithm.name !== availableAlgorithm.name
                    );
                    const algorithms = algorithmValue
                      ? [...filteredAlgorithms, algorithmValue]
                      : filteredAlgorithms;

                    onChange?.({
                      ...value,
                      algorithms,
                    } satisfies ParameterTuningAugurSettings);
                  }}
                  isTouched={true}
                  error={error}
                />
              );
            }
          )}
      </>
    );
  }

  return (
    <div className={styles.parameterTuning}>
      <h3 className={styles.headline}>Search Strategy</h3>

      <DropdownSelectInput
        key={'search-strategy-dropdown' + value.searchStrategy.name}
        id={'search-strategy-select'}
        name={'search-strategy-select'}
        className={styles.searchStrategySelect}
        placeholder={'Select'}
        options={options || []}
        value={options?.find((o) => o.value === value.searchStrategy.name)}
        onChange={(option: OptionType | null) => {
          if (!option) return;
          onChange?.({
            ...value,
            searchStrategy: {
              name: option.value,
            },
          } satisfies ParameterTuningAugurSettings);
        }}
        onBlur={onBlur}
      />
      {error?.global && <p className={styles.error}>{error.global}</p>}
      {renderSelectableAlgorithms()}
    </div>
  );
};

export default ParameterTuning;
