import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { JobStatus } from 'common/dist/constants/enums';
import { DashboardJob } from 'common/dist/types/job';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';
import { AugurCategory } from '../../components/molecules/augur-menu/types';

export const jobKeys = {
  all: () => ['job'] as const,
  some: (habitatCode: string, augurCode: string) =>
    [...jobKeys.all(), habitatCode, augurCode] as const,
  job: (habitatCode: string, augurCode: string, jobCode: string) =>
    [...jobKeys.some(habitatCode, augurCode), jobCode] as const,
  modelJob: (habitatCode: string, augurCode: string, modelCode: string) =>
    [...jobKeys.some(habitatCode, augurCode), modelCode] as const,
  jobs: (
    habitatCode: string,
    augurCode: string,
    modelCode?: string,
    type?: 'learning' | 'evaluation' | 'prediction',
    offset?: number,
    limit?: number
  ) =>
    [
      ...jobKeys.some(habitatCode, augurCode),
      modelCode,
      type,
      offset,
      limit,
    ] as const,
};

export const getJobs = (
  habitatCode: string,
  augurCode: string,
  status: JobStatus,
  modelCode?: string,
  type?: 'learning' | 'evaluation' | 'prediction',
  offset?: number,
  limit?: number
): CompletedApiRequest<DashboardJob[]> => {
  const query = qs.stringify(
    { modelCode, type, offset, limit, status },
    { addQueryPrefix: true }
  );
  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/jobs${query}`
  );
};

export const useJobForModel = (
  habitatCode: string,
  augurCode: string,
  status: JobStatus,
  selectedPageCategory?: AugurCategory,
  modelCode?: string,
  enabled = true
): UseQueryResult<DashboardJob[]> => {
  const location = useLocation();
  const key = jobKeys.modelJob(habitatCode, augurCode, modelCode);
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () => {
        const search = qs.parse(location.search, {
          ignoreQueryPrefix: true,
          allowEmptyArrays: true,
        });

        const compareSelection = search.compareSelection as string | undefined;

        const query = qs.stringify(
          { status, modelCode, selectedPageCategory, compareSelection },
          { addQueryPrefix: true }
        );
        return apiRequest(
          `/api/habitats/${habitatCode}/augurs/${augurCode}/job${query}`
        );
      }),
    {
      keepPreviousData: true,
      enabled: !!selectedPageCategory && enabled,
    }
  );
};
export const useInfiniteJobs = (
  habitatCode: string,
  augurCode: string,
  status: JobStatus,
  enabled = true,
  modelCode?: string,
  type?: 'evaluation' | 'prediction',
  limit = 20 // Default limit per page
): UseInfiniteQueryResult<{
  jobs: DashboardJob[];
  nextCursor: number | undefined;
}> => {
  const key = jobKeys.jobs(habitatCode, augurCode, modelCode, type);

  return useInfiniteQuery(
    key,
    async ({ pageParam = 0 }) => {
      console.debug(`Fetching jobs with offset: ${pageParam}, limit: ${limit}`);
      const jobs = await fetchQueryFn(key, () =>
        getJobs(
          habitatCode,
          augurCode,
          status,
          modelCode,
          type,
          pageParam,
          limit + 1 // Request one extra item to check if there are more
        )
      );

      const hasNextPage = jobs.length > limit;
      const paginatedJobs = hasNextPage ? jobs.slice(0, limit) : jobs;

      console.debug(
        `Fetched ${paginatedJobs.length} jobs, hasNextPage: ${hasNextPage}`
      );
      return {
        jobs: paginatedJobs,
        nextCursor: hasNextPage ? pageParam + limit : undefined,
      };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      keepPreviousData: true,
      enabled: enabled,
    }
  );
};
export const getJob = (
  habitatCode: string,
  augurCode: string,
  jobCode: string
): CompletedApiRequest<DashboardJob> =>
  apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/jobs/${jobCode}`
  );

export const useJob = (
  habitatCode: string,
  augurCode: string,
  jobCode: string
): UseQueryResult<DashboardJob> => {
  const key = jobKeys.job(habitatCode, augurCode, jobCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getJob(habitatCode, augurCode, jobCode))
  );
};
