import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import notificationsMsgs from 'common/dist/messages/notifications';
import { Augur } from 'common/dist/types/augur';
import { AugurSettings } from 'common/dist/types/augurSettings';
import {
  PostAugurRequestBody,
  PutAugurRequestBody,
  PutAugurSettingsRequestBody,
} from 'common/dist/types/requestBodies/augurs';
import { ExistsResponseBody } from 'common/dist/types/responseBodies/base';
import qs from 'qs';

import {
  apiRequest,
  CompletedApiRequest,
  deleteApiRequest,
  fetchQueryFn,
  postApiRequest,
  putApiRequest,
} from './_tools';
import { sendNotification } from '../../redux/modules/notifications.module';
import { useAppDispatch } from '../../store/store';
import { error as errorType } from '../notifications';

export const augurKeys = {
  all: () => ['augurs'] as const,
  some: (habitatCode: string) => [...augurKeys.all(), habitatCode] as const,
  augur: (habitatCode: string, augurCode: string) =>
    [...augurKeys.some(habitatCode), augurCode] as const,
  augurs: (habitatCode: string, archived?: boolean) =>
    [...augurKeys.some(habitatCode), archived] as const,
  add: (habitatCode: string) =>
    [...augurKeys.some(habitatCode), 'add'] as const,
  update: (habitatCode: string, augurCode: string) =>
    [...augurKeys.augur(habitatCode, augurCode), 'update'] as const,
  updateAlt: (habitatCode: string) =>
    [...augurKeys.some(habitatCode), 'update'] as const,
  delete: (habitatCode: string, augurCode: string) =>
    [...augurKeys.augur(habitatCode, augurCode), 'delete'] as const,
  name: (augurCode: string) => [...augurKeys.all(), 'name', augurCode] as const,
  exists: (habitatCode: string, augurName: string) =>
    [...augurKeys.some(habitatCode), 'exists', augurName] as const,
};

export const augurSettingsKeys = {
  some: (habitatCode: string, augurCode: string) =>
    [...augurKeys.augur(habitatCode, augurCode), 'settings'] as const,
  settings: (habitatCode: string, augurCode: string, modelCode: string) =>
    [...augurSettingsKeys.some(habitatCode, augurCode), modelCode] as const,
  update: (habitatCode: string, augurCode: string) =>
    [...augurSettingsKeys.some(habitatCode, augurCode), 'update'] as const,
  settingsHistory: (
    habitatCode: string,
    augurCode: string,
    offset: number,
    limit: number
  ) =>
    [...augurSettingsKeys.some(habitatCode, augurCode), offset, limit] as const,
};

export function getSettings(
  habitatCode: string,
  augurCode: string,
  modelCode?: string
): CompletedApiRequest<AugurSettings> {
  const query = qs.stringify({ modelCode }, { addQueryPrefix: true });

  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/settings${query}`
  );
}

export const useSettings = (
  habitatCode: string,
  augurCode: string,
  modelCode?: string
): UseQueryResult<AugurSettings> => {
  const key = augurSettingsKeys.settings(habitatCode, augurCode, modelCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getSettings(habitatCode, augurCode, modelCode))
  );
};

export function putSettings(
  habitatCode: string,
  augurCode: string,
  payload: PutAugurSettingsRequestBody
) {
  return putApiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/settings`,
    payload
  );
}

export function useUpdateSettings(habitatCode: string, augurCode: string) {
  const queryClient = useQueryClient();
  const key = augurSettingsKeys.update(habitatCode, augurCode);
  return useMutation(
    key,
    (payload: PutAugurSettingsRequestBody) =>
      fetchQueryFn(key, () => putSettings(habitatCode, augurCode, payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurSettingsKeys.some(habitatCode, augurCode),
        });
      },
    }
  );
}

export function getSettingsHistory(
  habitatCode: string,
  augurCode: string,
  offset?: number,
  limit?: number
): CompletedApiRequest<AugurSettings[]> {
  const query = qs.stringify({ offset, limit }, { addQueryPrefix: true });

  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/settings/history${query}`
  );
}

export const useSettingsHistory = (
  habitatCode: string,
  augurCode: string,
  offset?: number,
  limit?: number
): UseQueryResult<AugurSettings[]> => {
  const key = augurSettingsKeys.settingsHistory(
    habitatCode,
    augurCode,
    offset,
    limit
  );
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        getSettingsHistory(habitatCode, augurCode, offset, limit)
      ),
    {
      keepPreviousData: true,
    }
  );
};

export function getAugur(augurCode: string): CompletedApiRequest<Augur> {
  return apiRequest(`/api/augurs/${augurCode}`);
}

export const useAugur = (
  habitatCode: string,
  augurCode: string
): UseQueryResult<Augur> => {
  const key = augurKeys.augur(habitatCode, augurCode);
  return useQuery(key, () => fetchQueryFn(key, () => getAugur(augurCode)));
};

export function getAugurs(
  habitatCode: string,
  archived?: boolean
): CompletedApiRequest<Augur[]> {
  const query = qs.stringify({ archived }, { addQueryPrefix: true });
  return apiRequest(`/api/habitats/${habitatCode}/augurs${query}`);
}

export const useAugurs = (
  habitatCode: string,
  archived?: boolean
): UseQueryResult<Augur[]> => {
  const key = augurKeys.augurs(habitatCode, archived);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getAugurs(habitatCode, archived))
  );
};

export function putAugur(
  habitatCode: string,
  augurCode: string,
  payload: PutAugurRequestBody
) {
  return putApiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}`,
    payload
  );
}

export function useUpdateAugur(habitatCode: string, augurCode: string) {
  const queryClient = useQueryClient();
  const key = augurKeys.update(habitatCode, augurCode);
  return useMutation(
    key,
    (payload: PutAugurRequestBody) =>
      fetchQueryFn(key, () => putAugur(habitatCode, augurCode, payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.some(habitatCode),
        });
      },
    }
  );
}

/**
 * An alternative to useUpdateAugur, where the augurCode is only passed later when invoking the mutation function
 * Useful for when you can't use hooks where you know the augur code and vice versa
 * @param habitatCode
 */
export function useUpdateAugurWithAugurCode(
  habitatCode: string
): UseMutationResult {
  const queryClient = useQueryClient();
  const key = augurKeys.updateAlt(habitatCode);
  return useMutation(
    key,
    ({
      augurCode,
      payload,
    }: {
      augurCode: string;
      payload: PutAugurRequestBody;
    }) => fetchQueryFn(key, () => putAugur(habitatCode, augurCode, payload)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.some(habitatCode),
        });
      },
    }
  );
}

export function deleteAugur(
  habitatCode: string,
  augurCode: string
): CompletedApiRequest {
  return deleteApiRequest(`/api/habitats/${habitatCode}/augurs/${augurCode}`);
}

export function useDeleteAugur(
  habitatCode: string,
  augurCode: string
): UseMutationResult {
  const queryClient = useQueryClient();
  const key = augurKeys.delete(habitatCode, augurCode);
  return useMutation(
    key,
    () => fetchQueryFn(key, () => deleteAugur(habitatCode, augurCode)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.some(habitatCode),
        });
      },
    }
  );
}

export function postAugur(
  habitatCode: string,
  augur: PostAugurRequestBody
): CompletedApiRequest {
  return postApiRequest(`/api/habitats/${habitatCode}/augurs`, augur);
}

export function useAddAugur(habitatCode: string): UseMutationResult {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const key = augurKeys.add(habitatCode);
  return useMutation(
    key,
    (augur: PostAugurRequestBody) =>
      fetchQueryFn(key, () => postAugur(habitatCode, augur)),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: augurKeys.some(habitatCode),
        });
      },
      onError: (error) => {
        // only error notification is needed here, success notification is broadcast over sockets
        dispatch(
          sendNotification(
            notificationsMsgs.msgTitleAugurAddFailure.id,
            // @ts-ignore
            notificationsMsgs.msgDescriptionAugurAddFailure.id,
            errorType
          )
        );
      },
    }
  );
}

// TODO: this should be a PUT request (actually even a HEAD request)
export function checkAugurNameExists(
  habitatCode: string,
  augurName: string
): CompletedApiRequest<ExistsResponseBody> {
  return postApiRequest(`/api/habitats/${habitatCode}/augurs/exists`, {
    name: augurName,
  });
}

export const useAugurCodeExistsCheck = (
  augurCode: string
): UseQueryResult<boolean> => {
  const key = augurKeys.name(augurCode);
  return useQuery(key, async () => {
    const augur = await getAugur(augurCode);
    return !augur.error;
  });
};

// Mainly used to add the Augur name to the Jobs
export function getAugurName(augurCode: string): CompletedApiRequest<string> {
  return apiRequest<string>(`/api/augurs/${augurCode}/name`);
}

export const useAugurName = (augurCode: string): UseQueryResult<string> => {
  const key = augurKeys.name(augurCode);
  return useQuery(key, () => fetchQueryFn(key, () => getAugurName(augurCode)));
};

export function triggerPredictionRun(habitatCode, augurCode) {
  return postApiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/triggerpredictionrun`
  );
}

export function triggerLearningRun(habitatCode, augurCode) {
  return postApiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/triggerlearningrun`
  );
}

export function triggerEvaluationRun(habitatCode, augurCode) {
  return postApiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/triggerevaluationrun`
  );
}

export function updateRealtimePrediction(
  habitatCode,
  augurCode,
  realtimePrediction
) {
  return putApiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/realtime_prediction`,
    { realtimePrediction }
  );
}
