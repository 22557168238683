import { JOB_STATUS } from 'common/dist/constants/enums';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import {
  Redirect,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { useTimeTravel } from './hooks';
import TimeTravel from './TimeTravel';
import { TimeTravelEntry } from './types';
import { useSettings, useSettingsHistory } from '../../../core/api/augurs';
import { useInfiniteJobs, useJobForModel } from '../../../core/api/jobs';
import { useActiveModel, useModels } from '../../../core/api/mlModels';
import { useInfiniteModuleFiles } from '../../../core/api/modules';
import Busy from '../../atoms/busy/Busy';
import { AugurDetailsRouteParams } from '../../index/routes';
import { AugurCategory } from '../../molecules/augur-menu/types';
import { useSelectedDirPath } from '../../workbench/hooks';

export type Props = {
  selectedPageCategory?: AugurCategory;
  isDev?: boolean;
};

const UnifiedTimeTravel: FC<Props> = ({
  selectedPageCategory,
  isDev = false,
}) => {
  let entries: TimeTravelEntry[];
  let explicitlySelectedJob;
  let activeModelCode: string;
  const location = useLocation();
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();
  const pathPrefix = useRouteMatch().url;

  const { singleSelection } = useTimeTravel();

  // Dev-specific hooks
  const selectedDirPath = useSelectedDirPath();
  const index = isDev
    ? selectedDirPath.findIndex((x) => x.endsWith('.asr'))
    : -1;
  const {
    isLoading: isModuleFilesLoading,
    error: moduleFilesError,
    data: moduleFilesData,
    fetchNextPage: fetchNextFilesPage,
    hasNextPage: hasNextFilesPage,
    isFetchingNextPage: isFetchingNextFilesPage,
  } = useInfiniteModuleFiles(
    isDev ? contentArrayToPath(selectedDirPath.slice(0, index + 1), false) : '',
    isDev && index !== -1
  );

  // Live-specific hooks
  const { data: activeModel, isLoading: isActiveModelLoading } = useActiveModel(
    habitatCode,
    augurCode
  );
  const { data: activeSettings, isLoading: isActiveSettingsLoading } =
    useSettings(habitatCode, augurCode);
  const {
    data: jobsData,
    isLoading: isJobsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteJobs(habitatCode, augurCode, JOB_STATUS.FINISHED, !isDev);
  const { data: additionalJobs } = useJobForModel(
    habitatCode,
    augurCode,
    JOB_STATUS.FINISHED,
    selectedPageCategory,
    explicitlySelectedJob?.modelCode ?? activeModel?.code,
    !isDev
  );

  const jobs = jobsData ? jobsData.pages.flatMap((page) => page.jobs) : [];
  if (additionalJobs) {
    additionalJobs.forEach((additionalJob) => {
      if (!jobs.some((job) => job.code === additionalJob.code)) {
        jobs.push(additionalJob);
      }
    });
  }
  const reports = moduleFilesData
    ? moduleFilesData.pages.flatMap((page) => page.info.reports)
    : [];
  const latestAugurReports = moduleFilesData?.pages[0].info.latestReports
    ? Object.values(moduleFilesData?.pages[0].info.latestReports)
    : [];
  if (latestAugurReports) {
    latestAugurReports.forEach((additionalJob) => {
      if (!reports.some((job) => job.jobCode === additionalJob.jobCode)) {
        reports.push(additionalJob);
      }
    });
  }
  const { data: settingsHistory, isLoading: isSettingsHistoryLoading } =
    useSettingsHistory(habitatCode, augurCode);
  const { data: models, isLoading: isModelsLoading } = useModels(
    habitatCode,
    augurCode
  );
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if ((!isDev && isJobsLoading) || (isDev && isModuleFilesLoading)) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          console.log('Last element is intersecting. Fetching next page...');
          fetchNextPage();
        } else if (
          entries[0].isIntersecting &&
          hasNextFilesPage &&
          !isFetchingNextFilesPage
        ) {
          console.log('Last element is intersecting. Fetching next page...');
          fetchNextFilesPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [
      isJobsLoading,
      isModuleFilesLoading,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      fetchNextFilesPage,
      hasNextFilesPage,
      isFetchingNextFilesPage,
    ]
  );

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);
  if (isDev) {
    if (isModuleFilesLoading) return <Busy isBusy={true} />;
    if (!moduleFilesData) return <div>{moduleFilesError}</div>;
  } else {
    if (
      isActiveModelLoading ||
      isActiveSettingsLoading ||
      isJobsLoading ||
      isSettingsHistoryLoading ||
      isModelsLoading
    )
      return <Busy isBusy={true} />;
  }

  if (isDev) {
    entries = reports.map((report) => ({
      code: report.jobCode,
      title: report.jobCode,
      timestamp: report.createdAt,
      type: report.jobType,
      modelCode: report.modelCode,
      jobCode: report.jobCode,
    }));
    activeModelCode = latestAugurReports.find(
      (report) => report.jobType === selectedPageCategory
    )?.modelCode;
    explicitlySelectedJob = reports.find(
      (report) => report.jobCode === singleSelection
    );
  } else {
    entries = jobs.map((job) => ({
      code: job.code,
      type: job.type,
      settingsCode: job.augurSettingsCode,
      modelCode: job.modelCode,
      jobCode: job.code,
      timestamp: job.createdAt,
      title: job.reportCode,
    }));
    activeModelCode = activeModel.code;
    console.log('compare', jobs, singleSelection);
    explicitlySelectedJob = jobs.find((job) => job.code === singleSelection);
  }

  // when in selection mode, check whether the selected page category fits the selected job
  // redirect if this is not the case
  if (
    singleSelection &&
    selectedPageCategory !==
      (isDev ? explicitlySelectedJob?.jobType : explicitlySelectedJob?.type)
  ) {
    return (
      <Redirect
        to={
          isDev
            ? `/${explicitlySelectedJob?.jobType}${location.search}`
            : `${pathPrefix}/${explicitlySelectedJob?.type}${location.search}`
        }
      />
    );
  }

  return (
    <>
      <TimeTravel
        entries={entries}
        models={isDev ? undefined : models}
        settingsHistory={isDev ? undefined : settingsHistory}
        activeModelCode={activeModelCode}
        selectedPageCategory={selectedPageCategory}
        lastElementRef={lastElementRef}
        fetchNextPage={isDev ? fetchNextFilesPage : fetchNextPage}
      />
    </>
  );
};

export default UnifiedTimeTravel;
