import React, { FC } from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';

import styles from './styles.module.scss';
import { ThemeColor, ThemeColorModifier, useThemeColor } from '../../../utils';
import LoadingPlaceholder from '../loading-placeholder/LoadingPlaceholder';

export type Props = {
  themeColor: ThemeColor;
  modifier?: ThemeColorModifier;
  isLoading?: boolean;
};

const ThemedSwitch: FC<Props & ReactSwitchProps> = ({
  children,
  themeColor,
  modifier,
  isLoading,
  ...props
}) => {
  const color = useThemeColor(themeColor, modifier);

  if (isLoading) {
    return (
      <div className={styles.loadingSwitch}>
        <LoadingPlaceholder width={'40px'} height={'20px'} />
      </div>
    );
  }

  return <Switch onColor={color || undefined} {...props} />;
};

export default ThemedSwitch;
