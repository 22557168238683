import { SelectedSchedule } from '../utils/schedules';

export type SuperTypeType =
  | JobAugur['superType']
  | JobCodeCapsule['superType']
  | JobApp['superType']
  | JobBackup['superType']
  | JobModule['superType'];

export type JobGroupDetailsType = {
  name?: string;
  description?: string;
  jobs: Job[];
  jobGroupTopology: JobGroupTopologyType[];
  status?: StatusType;
  addedToPreQueue?: string;
  created?: string;
  createdBy?: string;
  finished?: string;
  schedule?: SelectedSchedule;
  priority?: PriorityType;
};

/** The Job types (available jobTypes depend on the Job superType) */
export const JOB_TYPE = {
  LEARNING: 'learning', // superType=augur
  EVALUATION: 'evaluation', // superType=augur
  PREDICTION: 'prediction', // superType=augur
  REALTIME_SCORING: 'realtimePrediction', // superType=augur
  BUILD_CODE_CAPSULE: 'build-code-capsule',
  RUN_CODE_CAPSULE: 'run-code-capsule',
  BUILD_APP: 'build-app',
  TRANSFER: 'transfer',
  BACKUP: 'backup',
  RESTORE: 'restore',
  BUILD_MODULE: 'build-module',
} as const;
export type JobType = (typeof JOB_TYPE)[keyof typeof JOB_TYPE];

export const AUGUR_JOBS = [
  JOB_TYPE.LEARNING,
  JOB_TYPE.EVALUATION,
  JOB_TYPE.PREDICTION,
  JOB_TYPE.REALTIME_SCORING,
] as const;

export const JobTypeToSpeaking = {
  [JOB_TYPE.LEARNING]: 'Learning',
  [JOB_TYPE.EVALUATION]: 'Evaluation',
  [JOB_TYPE.PREDICTION]: 'Prediction',
  [JOB_TYPE.REALTIME_SCORING]: 'Realtime Prediction',
  [JOB_TYPE.BUILD_CODE_CAPSULE]: 'Build',
  [JOB_TYPE.RUN_CODE_CAPSULE]: 'Run',
  [JOB_TYPE.BUILD_APP]: 'Build',
  [JOB_TYPE.TRANSFER]: 'Transfer',
  [JOB_TYPE.BACKUP]: 'Backup',
  [JOB_TYPE.RESTORE]: 'Restore',
  [JOB_TYPE.BUILD_MODULE]: 'Build',
} as const;

/** Status of the Job */
export type StatusType =
  | 'waiting'
  | 'waiting-in-execution-queue'
  | 'triggered'
  | 'running'
  | 'in-progress'
  | 'success'
  | 'successful'
  | 'cancelled'
  | 'failure';

export const EXECUTION_TYPE = {
  OTHER: 'other',
  SPARK: 'spark',
} as const;
export type ExecutionType =
  (typeof EXECUTION_TYPE)[keyof typeof EXECUTION_TYPE];

// --- Job Types by superType. The super type defines the highest hierarchy in the Job and determines a lot of the
//  available fields for this job
/** Fields that apply for all super types of Jobs */
interface GenericJobFields {
  /** x-Position */
  x?: number;
  /** y-Position */
  y?: number;
  /** Level */
  level?: number;
  /** Is the Job expanded? */
  expanded?: boolean;

  /** Was the job injected into the existing JobGroup? (like a learning Job immediately after an evaluation Job) */
  wasInjected: boolean;
  /** Timestamp of when the job was added to the execution queue. in JDBC timestamp escape format ("yyyy-mm-dd hh:mm:ss.fff") where f indicates milliseconds */
  addedToExecutionQueue: string | undefined;
  /** Determines the color of the stroke via the CSS class.*/
  status?: StatusType;
  /** Code of the Job */
  jobCode: string;
  /** Display name for the job */
  superName?: string; // For example the Name of the Augur
  /** Execution type of the job (spark, ...) */
  executionType?: ExecutionType;
  /** Priority of the Job */
  priority?: string;
  /** Optional name to add for identification, for example running a CodeCapsule with certain Notebooks/Parameters */
  name?: string;
}

export interface JobAugur extends GenericJobFields {
  superType: 'augur';
  jobType:
    | typeof JOB_TYPE.LEARNING
    | typeof JOB_TYPE.EVALUATION
    | typeof JOB_TYPE.PREDICTION;
  augurCode: string;
  moduleInformation: {
    code: string;
    name: string;
    versionNumber: string;
  };
}

export type CodeCapsuleParameters = Record<string, string>;

export type CodeCapsuleResources = {
  memory?: string;
  cpu?: string;
  gpu?: number;
};

export interface JobCodeCapsule extends GenericJobFields {
  superType: 'code-capsule';
  jobType:
    | typeof JOB_TYPE.BUILD_CODE_CAPSULE
    | typeof JOB_TYPE.RUN_CODE_CAPSULE;
  repositoryCode: string;
  codeCapsuleCode: string;
  capsuleVersionNumber: string;
  habitatCode: string;
  notebooksToExecute: string[];
  capsuleImage: string;
  parameters?: CodeCapsuleParameters;
  resources?: CodeCapsuleResources;
  requestCredentialsFor: string[];
  gpu?: Gpu;
}

export interface JobApp extends GenericJobFields {
  superType: 'app';
  jobType: typeof JOB_TYPE.BUILD_APP;
  appName: string;
  appVersionNumber: string;
}

export interface JobBackup extends GenericJobFields {
  superType: 'backup';
  jobType: typeof JOB_TYPE.BACKUP | typeof JOB_TYPE.RESTORE;
  templateId: string;
  backupId: string;
}

export interface JobModule extends GenericJobFields {
  superType: 'module';
  jobType: typeof JOB_TYPE.BUILD_MODULE;
  repositoryCode: string;
  moduleCode: string;
  moduleVersionNumber: string;
  habitatCode: string;
  moduleImage: string;
  parameters?: CodeCapsuleParameters;
  resources?: CodeCapsuleResources;
  gpu?: Gpu;
}

export type Job = JobAugur | JobCodeCapsule | JobApp | JobBackup | JobModule;

export type JobGroupTopologyType = {
  jobCode: string;
  predecessors: string[];
  successors: string[];
};

export type PriorityType = 'highest' | 'high' | 'medium' | 'low';
export type TriggerType = 'timed' | 'delayed' | 'cron' | 'rest' | 'manual';

export type InputSchedule = {
  trigger: TriggerType;
  jobGroupInput: JobGroupInputType;
  trigDelayed?: string;
  timeZone: string;
  trigTimed?: string;
  trigCron?: string;
};

/**
 * JobGroupType which is the backend JobGroupOutput and differs in the orchestration internal representation
 * mostly in how the topology is represented
 */
export type JobGroupType = {
  code: string;
  name?: string;
  description?: string;
  jobs: Job[];
  jobGroupTopology: JobGroupTopologyType[];
  /** Timestamp in JDBC timestamp escape format ("yyyy-mm-dd hh:mm:ss.fff") where f indicates milliseconds */
  addedToPreQueue: string;
  /** Timestamp in JDBC timestamp escape format ("yyyy-mm-dd hh:mm:ss.fff") where f indicates milliseconds */
  finished: string | null;
  priority: PriorityType;
  status: 'waiting' | 'in-progress' | 'successful' | 'failure' | 'cancelled';
  trigger: TriggerType;
  createdBy?: string;
};

export type JobGroupInputType = {
  name?: string;
  description?: string;
  jobs: Job[];
  jobGroupTopology: JobGroupTopologyType[];
  priority?: PriorityType;
  trigger: TriggerType;
};

export type GenericScheduleFields = {
  scheduleCode: string;
  /** Description of the scheduled JobGroup */
  jobGroupInput: JobGroupInputType;
  /** Timestamp in JDBC timestamp escape format ("yyyy-mm-dd hh:mm:ss.fff") where f indicates milliseconds */
  created: string;
  createdBy?: string;
  trigTimed: SelectedSchedule['trigTimed'];
  trigDelayed?: string;
};

export type TimedSchedule = {
  trigger: 'timed';
  trigTimed: SelectedSchedule['trigTimed'];
};

export type DelayedSchedule = {
  trigger: 'delayed';
  trigDelayed: string;
};

export type RestSchedule = {
  trigger: 'rest';
};

export type ManualSchedule = {
  trigger: 'manual';
};

export type JobScheduleType = GenericScheduleFields &
  (TimedSchedule | DelayedSchedule | RestSchedule | ManualSchedule);

/**
 * An AltaSigma job as viewed by the dashboard backend (and not the orchestration)
 */
export interface DashboardJob {
  code: string;
  type:
    | typeof JOB_TYPE.LEARNING
    | typeof JOB_TYPE.EVALUATION
    | typeof JOB_TYPE.PREDICTION;
  status: string;
  duration: number;
  augurCode: string;
  augurSettingsCode?: string;
  modelCode?: string;
  reportCode?: string;
  createdAt: Date;
}

export type DiscreteProgressSteps = {
  total: number;
  started: number;
  completed: number;
};

export interface K8sResources {
  cpuRequest?: string;
  cpuLimit?: string;
  memoryRequest?: string;
  memoryLimit?: string;
  useGpu?: boolean;
  gpuRequest?: string;
  gpuLimit?: string;
  gpuProduct?: Gpu;
}

// Same type as in CodeCapsules
export type Gpu = { model: string };
