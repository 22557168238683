import { defineMessages } from 'react-intl';

export default defineMessages({
  editMode: {
    id: 'augur.dev.edit_mode',
    defaultMessage: 'Edit Mode',
  },

  invalidArrangement: {
    id: 'augur.dev.invalid.arrangement',
    defaultMessage: 'Invalid arrangement',
  },
  invalidElements: {
    id: 'augur.dev.invalid.elements',
    defaultMessage: `{ nElements } invalid element(s)\n`,
  },
  invalidPages: {
    id: 'augur.dev.invalid.pages',
    defaultMessage: `{ nPages } invalid page(s)\n`,
  },

  invalidSettings: {
    id: 'augur.dev.invalid.settings',
    defaultMessage: 'There are invalid settings.',
  },
  invalidSettingsElements: {
    id: 'augur.dev.invalid.settings_elements',
    defaultMessage: 'Page has invalid settings elements',
  },

  invalidModuleConfigurationFileTitle: {
    id: 'augur.dev.invalid.file.module_config.title',
    defaultMessage: 'Invalid Module Configuration file',
  },
  invalidModuleConfigurationFileDescription: {
    id: 'augur.dev.invalid.file.module_config.description',
    defaultMessage:
      'There was an error while parsing the .asconfig.json file. Please fix potential errors manually or reset the DevAugur by deleting the file.',
  },
});
