import React, { FC, useEffect, useState } from 'react';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import InputError from '../../../../../atoms/input-error/InputError';
import CommunicationGroupsTable from './CommunicationGroupsTable';
import { v4 as uuidv4 } from 'uuid';
import { CommunicationGroup } from 'common/dist/types/module.optimization';
import _ from 'lodash';
import { FiPlus } from 'react-icons/fi';
import { AugurSettingsProps } from '../../types/meta';
import { CampaignOptimizationCommunicationGroupsAugurSettings, CampaignOptimizationCommunicationGroupsConfig, CampaignOptimizationCommunicationGroupsValidationError } from './type';

export type Props = AugurSettingsProps<
  CampaignOptimizationCommunicationGroupsAugurSettings,
  CampaignOptimizationCommunicationGroupsConfig,
  CampaignOptimizationCommunicationGroupsValidationError
>;

export type CommunicationGroupsErrorType = {
  global: string;
  rows: {
    [constraintId: string]: {
        name?: string;
        description?: string;
        communicationIds?: string;
    }
  }
};

const CampaignOptimizationCommunicationGroups: FC<Props> = (props) => {
  const { config, value = [], onChange, onBlur, error, invalid } = props;
  const { communications = [] } = config;

  const [addedNewRow, setAddedNewRow] = useState(false);

  const handleChange = (updatedCommunicationGroups: CommunicationGroup[]) => {
    onChange?.(updatedCommunicationGroups);
  }

  // Update communicationGroups if the config changes
  useEffect(() => {
    const updatedCommunicationGroups = value.map(communicationGroup => {
      const updatedCommunicationGroup = { ...communicationGroup };

      // Filter out invalid communicationIds
      updatedCommunicationGroup.communicationIds = communicationGroup.communicationIds.filter(
        communicationId => communications.some(communication => communication.id === communicationId)
      );

      return updatedCommunicationGroup;
    });

    handleChange(updatedCommunicationGroups);
  }, [communications]);

  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        {error?.global && (
          <InputError
            touched={true}
            error={error.global}
          />
        )}
      </div>

      <div className={commonStyles.tableContainer}>
        <CommunicationGroupsTable
          communications={communications || []}
          addedNewRow={addedNewRow}
          rowIndex={value? value?.length - 1 : 0}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          invalid={invalid}
          error={error}
        />
      </div>

      <div className={commonStyles.AddIconContainer}>
        <div
          className={commonStyles.AddIcon}
          onClick={() => {
            handleChange([...value, { id: uuidv4(), name: '', communicationIds: [] }]);
            setAddedNewRow(true);
          }}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
};

export default CampaignOptimizationCommunicationGroups;
