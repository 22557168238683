import messages from 'common/dist/messages/s3';
import { DSType } from 'common/dist/types/dataManagement/dataSource';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import { S3BucketSelectAugurSettings, S3BucketSelectConfig } from './type';
import {
  useBucketsWithPermission,
  useDataSources,
} from '../../../../../../core/api/data';
import { extractErrorMessage } from '../../../../../../utils';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import InputError from '../../../../../atoms/input-error/InputError';
import { HabitatRouteParams } from '../../../../../index/routes';
import ErrorPage from '../../../../../pages/error-boundary/ErrorPage';
import { OptionComponent } from '../../common/DSOptionComponent';
import { OptionType } from '../../common/type';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  S3BucketSelectAugurSettings,
  S3BucketSelectConfig
>;

const S3BucketSelect: FC<Props> = (props) => {
  const {
    onChange,
    isTouched,
    error,
    value,
    disabled,
    readOnly,
    portalTarget,
  } = props;
  const dataSources = useDataSources();

  const dataSourceOptions: OptionType[] = (
    dataSources?.data?.filter((value) => value.ds_type === DSType.S3) || []
  ).map((ds) => ({
    ...ds,
    label: ds.name,
    value: ds.code,
  }));
  const { habitatCode } = useParams<HabitatRouteParams>();

  const {
    data: bucketsData,
    isError: isBucketsError,
    error: bucketsError,
  } = useBucketsWithPermission(value?.dataSourceCode, habitatCode ?? undefined);

  const renderBucketsDropdown = () => {
    if (isBucketsError) {
      const errorMessage = extractErrorMessage(bucketsError);
      return (
        <ErrorPage
          errorHeadline={{
            id: 'no-id',
            defaultMessage: 'Error while fetching buckets',
          }}
          errorDescription={{
            defaultMessage: errorMessage,
            id: 'no-id',
          }}
        />
      );
    } else {
      const bucketsOptions: OptionType[] = (bucketsData || []).map((k) => ({
        label: k.name,
        value: k.name,
      }));
      return (
        <div data-testid={'bucket-select'}>
          <DropdownSelectInput
            onChange={(e: OptionType) => {
              onChange({ ...value, bucketName: e.value });
            }}
            value={
              bucketsOptions.find(
                (option) => option.value === value?.bucketName
              ) || ''
            }
            label={{ id: 'todo', defaultMessage: 'S3 Bucket' }}
            placeholder={{
              id: 'todo',
              defaultMessage: 'No S3 Bucket selected',
            }}
            options={bucketsOptions}
            menuPortalTarget={portalTarget}
            disabled={disabled}
          />
        </div>
      );
    }
  };

  return (
    <div data-testid={'data-source-select'}>
      <div className={styles.row}>
        <div className={styles.TextInputLineError}>
          {error && <InputError touched={isTouched} error={error} />}
        </div>
        <DropdownSelectInput
          onChange={(e: OptionType) => {
            onChange({ ...value, dataSourceCode: e.value });
          }}
          value={
            dataSourceOptions.find(
              (option) => option.value === value?.dataSourceCode
            ) || ''
          }
          label={{ id: 'todo', defaultMessage: 'S3 Data Source' }}
          placeholder={{
            id: 'todo',
            defaultMessage: 'No S3 Data Source selected',
          }}
          options={dataSourceOptions}
          customComponents={{ Option: OptionComponent }}
          menuPortalTarget={portalTarget}
          disabled={disabled}
        />
      </div>
      {(value?.dataSourceCode || readOnly) && renderBucketsDropdown()}
      {habitatCode ? (
        <FormattedMessage {...messages.msgPermissionHabitatUser}>
          {(text) => <p className={styles.label}>{text}</p>}
        </FormattedMessage>
      ) : (
        <FormattedMessage {...messages.msgPermissionUser}>
          {(text) => <p className={styles.label}>{text}</p>}
        </FormattedMessage>
      )}
    </div>
  );
};

export default S3BucketSelect;
