import archiveMsgs from 'common/dist/messages/augurs.modelArchive';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import ModelsTable, { ModelTableEntry } from './ModelsTable';
import styles from './styles.module.scss';
import { useModelHistory } from '../../../core/api/mlModels';
import InputError from '../../atoms/input-error/InputError';
import { AugurDetailsRouteParams } from '../../index/routes';

interface Props {}

const renderError = (error) => {
  // After navigating from the files in the workbench and then to the DevAugur an error gets thrown.
  return (
    <div className={styles.modelArchive}>
      <InputError error={error} touched={true} />
    </div>
  );
};

const renderLoaded = (
  modelTableEntries: ModelTableEntry[],
  habitatCode: string,
  augurCode: string
) => {
  return (
    <div className={styles.modelArchive}>
      <div className={styles.headlineContainer}>
        <FormattedMessage {...archiveMsgs.msgHeadline}>
          {(txt) => <span className={styles.headline}>{txt}</span>}
        </FormattedMessage>
      </div>

      <div className={styles.body}>
        <ModelsTable
          modelTableEntries={modelTableEntries}
          habitatCode={habitatCode}
          augurCode={augurCode}
        />
      </div>
    </div>
  );
};

const ModelArchive: FC<Props> = (props) => {
  const { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data, error } = useModelHistory(
    habitatCode,
    augurCode,
    undefined,
    0,
    1000 // FIXME: should be solved with paging
  );
  const modelTableEntries: ModelTableEntry[] = (data || []).map(
    (modelHistoryEntry) => {
      return {
        modelCode: modelHistoryEntry.modelCode,
        activeSince: modelHistoryEntry.activeSince,
        activeUntil: modelHistoryEntry.activeUntil,
      };
    }
  );

  if (error) return renderError(error);
  else return renderLoaded(modelTableEntries, habitatCode, augurCode);
};

export default ModelArchive;
