import React, { FC, useState } from 'react';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import ContactPoliciesTable from './ContactPoliciesTable';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FiPlus } from 'react-icons/fi';
import InputError from '../../../../../atoms/input-error/InputError';
import { CampaignOptimizationContactPoliciesAugurSettings, CampaignOptimizationContactPoliciesConfig, CampaignOptimizationContactPoliciesValidationError } from './type';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  CampaignOptimizationContactPoliciesAugurSettings,
  CampaignOptimizationContactPoliciesConfig,
  CampaignOptimizationContactPoliciesValidationError
>;

export type ContactPoliciesErrorType = {
  global?: string;
  rows?: {
    [constraintId: string]: {
      id?: string;
      name?: string;
      description?: string;
      contactPolicyType?: string;
      operator?: string;
      value?: string;
    }
  }
};

const CampaignOptimizationContactPolicies: FC<Props> = (props) => {
  const { value = [], onChange, error, onBlur } = props;
  const [addedNewRow, setAddedNewRow] = useState(false);

  const expandAutomatically = () => {
    const isEmpty = _.isEmpty(value);
    if (isEmpty && !addedNewRow) {
      setAddedNewRow(true);
      onChange?.([...value, { id: uuidv4() }]);
    }
  };

  expandAutomatically();

  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        {error?.global && (
          <InputError
            touched={true}
            error={error.global}
          />
        )}

      </div>
      <div className={commonStyles.tableContainer}>
        <ContactPoliciesTable
          addedNewRow={addedNewRow}
          rowIndex={value?.length - 1}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
        />
      </div>

      <div className={commonStyles.AddIconContainer}>
        <div
          className={commonStyles.AddIcon}
          onClick={() => {
            onChange?.([...(value || []), { id: uuidv4() }]);
            setAddedNewRow(true);
          }}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
}

export default CampaignOptimizationContactPolicies;