import React, { FC, useEffect, useState } from 'react';
import Table, { RenderColumn } from '../../../../../molecules/table/Table';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import {
  Channel,
  Communication,
  Propensity,
} from 'common/dist/types/module.optimization';
import classNames from 'classnames';
import AddCommunication from './AddCommunication';
import { CommunicationsErrorType } from './CampaignOptimizationCommunications';
import { DeepPartial } from 'react-hook-form';
import styles from './styles.module.scss'; 

type Props = {
  /** List of channels defined in a previous step */
  channels: Channel[];
  /** List of propensities defined in a previous step */
  propensities: Propensity[];
  addedNewRow?: boolean;
  rowIndex: number;
  value: Communication[],
  onChange?: (updatedCommunications: Communication[]) => void,
  onBlur?: React.FocusEventHandler;
  invalid?: boolean;
  error?: DeepPartial<CommunicationsErrorType>;
};

const CommunicationsTable: FC<Props> = (props) => {
  const {
    channels,
    propensities,
    addedNewRow,
    rowIndex,
    value,
    onChange,
    onBlur,
    invalid,
    error,
  } = props;

  const [visible, setShow] = useState({
    index: addedNewRow ? value.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  });

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : value.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex, value.length]);

  const renderColumns: RenderColumn<Communication, keyof Communication>[] = [
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      // @ts-ignore
      renderCell: (name: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communication.id]?.name
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      // @ts-ignore
      renderCell: (description: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communication.id]?.description
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'channelId',
      renderHeader: () => <span>Channel</span>,
      // @ts-ignore
      renderCell: (channelId?: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communication.id]?.channelId
          })}
        >
          {channels.find((ch) => ch.id === channelId)?.name}
        </span>
      ),
    },
    {
      key: 'propensityId',
      renderHeader: () => <span>Propensity</span>,
      // @ts-ignore
      renderCell: (propensityId?: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communication.id]?.propensityId
          })}
        >
          {propensities.find((al) => al.id === propensityId)?.valueId}
        </span>
      ),
    },
    {
      key: 'boostFactor',
      renderHeader: () => <span>Boost Factor</span>,
      // @ts-ignore
      renderCell: (boostFactor?: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communication.id]?.boostFactor
          })}
        >
          {boostFactor}
        </span>
      ),
    },
    {
      key: 'variableCosts',
      renderHeader: () => <span>Variable Costs (Optional)</span>,
      // @ts-ignore
      renderCell: (variableCosts?: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communication.id]?.variableCosts
          })}
        >
          {variableCosts}
        </span>
      ),
    },
    {
      key: 'fixedCosts',
      renderHeader: () => <span>Fixed Costs (Optional)</span>,
      // @ts-ignore
      renderCell: (fixedCosts?: string, communication: Communication) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[communication.id]?.fixedCosts
          })}
        >
          {fixedCosts}
        </span>
      ),
    },
  ];
  
  const onRemove = (removedRow: Communication) => {
    const updatedCommunication = value.filter((com) => com.id !== removedRow.id);
    onChange?.(updatedCommunication);
  };

  const renderClickedRow = (
    element: Communication,
    rowIndex: number,
    fallbackFn: unknown
  ) => {
    if (visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddCommunication
                rowIndex={rowIndex}
                constraintId={element.id}
                channels={channels}
                propensities={propensities}
                removableRows={visible.shown}
                onRemoveRow={onRemove}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                invalid={invalid}
                error={error}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table<Communication>
      data={value}
      renderColumns={renderColumns}
      showHeader
      roundedBorder
      removableRows={true}
      verticalAlignMiddle
      onRemoveRow={onRemove}
      addlRowClassname={(rowIndex: number, communication: Communication) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!error?.rows?.[communication.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(_row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default CommunicationsTable;
