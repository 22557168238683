import classNames from 'classnames';
import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import { FieldInputProps } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';
import InputError from '../../input-error/InputError';

export interface TextInputLineProps {
  label?: string | MessageDescriptor;
  placeholder?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  wrapMultipleErrorLines?: boolean;
  onToggle?: (isCollapsed: boolean) => void;
}

// TODO: Ideally this component should not depend on FieldInputProps (but its rather convenient)
export type Props = TextInputLineProps & Partial<FieldInputProps<string>>;

// Don't use this directly, use the intld version
const TextInputLine: FC<DeIntl<Props>> = (props) => {
  const {
    placeholder,
    label,
    wrapMultipleErrorLines,
    isTouched = false,
    isDirty = false,
    invalid = false,
    error,
    disabled,
    onChange,
    onBlur,
    name,
    inputRef,
    isValidating,
    value,
  } = props;
  const fieldClassName = classNames(styles.TextInputLineInput, {
    [styles.error]: isTouched && error,
    [styles.success]: !invalid && isDirty,
    [styles.pending]: isValidating && isDirty,
  });

  function renderBody() {
    return (
      <>
        <input
          data-testid={label}
          type='text'
          className={fieldClassName}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          ref={inputRef}
          name={name}
          value={value || ''}
          aria-invalid={error ? 'true' : 'false'}
        />
        {!invalid && !disabled && isDirty && (
          <div className={styles.TextInputLineSuccess}>
            <span className='icon-ok' />
          </div>
        )}
      </>
    );
  }

  function renderHeader() {
    return (
      <div className={styles.TextInputLineHeader}>
        {label && <p className={styles.TextInputLineLabel}>{label}</p>}
        {error && (
          <div
            className={styles.TextInputLineError}
            style={{
              whiteSpace: wrapMultipleErrorLines ? 'normal' : 'nowrap',
            }}
          >
            <InputError touched={isTouched} error={error} />
          </div>
        )}
      </div>
    );
  }

  function renderDefaultElement() {
    return (
      <div className={styles.TextInputLine}>
        {renderHeader()}
        {renderBody()}
      </div>
    );
  }

  return renderDefaultElement();
};

export const IntlTextInputLine: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={TextInputLine} props={props} />;
};

export default IntlTextInputLine;
