import { connect, ConnectedProps } from 'react-redux';
import OverlayAugurDetails from './OverlayAugurDetails';
import {
  triggerEvaluationJob,
  triggerLearningJob,
  triggerPredictionJob,
} from '../../../../redux/modules/triggerJobs.module';

export const mapDispatchToProps: {
  triggerLearningJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  triggerEvaluationJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  triggerPredictionJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
} = {
  triggerLearningJob,
  triggerEvaluationJob,
  triggerPredictionJob,
};

const connector = connect(null, mapDispatchToProps);
export type CProps = ConnectedProps<typeof connector>;
export default connector(OverlayAugurDetails);
