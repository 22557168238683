import { createAction } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';
import { sendNotification } from './notifications.module';
import { error as errorNotification, event } from '../../core/notifications';
import * as Api from '../../core/api';

export const triggerLearningJob = createAction(
  'trigger learning job',
  (habitatCode, augurCode, augurName) => ({ habitatCode, augurCode, augurName })
);

export const triggerEvaluationJob = createAction(
  'trigger evaluain job',
  (habitatCode, augurCode, augurName) => ({ habitatCode, augurCode, augurName })
);

export const triggerPredictionJob = createAction(
  'trigger prediction job',
  (habitatCode, augurCode, augurName) => ({
    habitatCode,
    augurCode,
    augurName,
  })
);

export const triggerRunCodeCapsuleJob = createAction(
  'trigger run code-capsule job',
  (
    repositoryName,
    repoCode,
    capsuleVersionNumber,
    capsuleImage,
    codeCapsuleCode,
    notebooksToExecute,
    resources,
    parameters,
    habitatCode,
    requestCredentialsFor,
    name,
    priority,
    gpu
  ) => ({
    repositoryName,
    repoCode,
    capsuleVersionNumber,
    capsuleImage,
    codeCapsuleCode,
    notebooksToExecute,
    resources,
    parameters,
    habitatCode,
    requestCredentialsFor,
    name,
    gpu,
    priority,
  })
);

export function* triggerLearningJobSaga({
  payload: { habitatCode, augurCode, augurName },
}) {
  const { response, error } = yield call(
    Api.augurs.triggerLearningRun,
    habitatCode,
    augurCode
  );
  if (response) {
    yield put(
      sendNotification(
        'notifications.title.learning_job_triggered',
        'notifications.description.learning_job_triggered',
        event,
        { augurName }
      )
    );
  } else {
    yield put(
      sendNotification(
        'notifications.title.learning_job_trigger_failure',
        'notifications.description.learning_job_trigger_failure',
        errorNotification,
        { augurName }
      )
    );
  }
}

export function* triggerEvaluationJobSaga({
  payload: { habitatCode, augurCode, augurName },
}) {
  const { response, error } = yield call(
    Api.augurs.triggerEvaluationRun,
    habitatCode,
    augurCode
  );
  if (response) {
    yield put(
      sendNotification(
        'notifications.title.evaluation_job_triggered',
        'notifications.description.evaluation_job_triggered',
        event,
        { augurName }
      )
    );
  } else {
    yield put(
      sendNotification(
        'notifications.title.evaluation_job_trigger_failure',
        'notifications.description.evaluation_job_trigger_failure',
        errorNotification,
        { augurName }
      )
    );
  }
}

export function* triggerPredictionJobSaga({
  payload: { habitatCode, augurCode, augurName },
}) {
  const { response, error } = yield call(
    Api.augurs.triggerPredictionRun,
    habitatCode,
    augurCode
  );
  if (response) {
    yield put(
      sendNotification(
        'notifications.title.prediction_job_triggered',
        'notifications.description.prediction_job_triggered',
        event,
        { augurName }
      )
    );
  } else {
    yield put(
      sendNotification(
        'notifications.title.prediction_job_trigger_failure',
        'notifications.description.prediction_job_trigger_failure',
        errorNotification,
        { augurName }
      )
    );
  }
}

export function* triggerRunCodeCapsuleJobSaga({
  payload: {
    repositoryName,
    repoCode,
    capsuleVersionNumber,
    capsuleImage,
    codeCapsuleCode,
    notebooksToExecute,
    resources,
    parameters,
    habitatCode,
    requestCredentialsFor,
    name,
    gpu,
    priority,
  },
}) {
  const { response, error } = yield call(
    Api.codeCapsules.triggerRun,
    repositoryName,
    repoCode,
    capsuleVersionNumber,
    capsuleImage,
    codeCapsuleCode,
    notebooksToExecute,
    resources,
    parameters,
    habitatCode,
    requestCredentialsFor,
    name,
    gpu,
    priority
  );

  if (response) {
    yield put(
      sendNotification(
        'notifications.title.run_code_capsule_job_triggered',
        'notifications.description.run_code_capsule_job_triggered',
        event
      )
    );
  } else {
    yield put(
      sendNotification(
        'notifications.title.run_code_capsule_job_trigger_failure',
        'notifications.description.run_code_capsule_job_trigger_failure',
        errorNotification
      )
    );
  }
}

export function* watchTriggerLearningJob() {
  yield takeEvery(triggerLearningJob.getType(), triggerLearningJobSaga);
}

export function* watchTriggerEvaluationJob() {
  yield takeEvery(triggerEvaluationJob.getType(), triggerEvaluationJobSaga);
}

export function* watchTriggerPredictionJob() {
  yield takeEvery(triggerPredictionJob.getType(), triggerPredictionJobSaga);
}

export function* watchTriggerRunCodeCapsuleJob() {
  yield takeEvery(
    triggerRunCodeCapsuleJob.getType(),
    triggerRunCodeCapsuleJobSaga
  );
}
