import { Taxonomy } from 'common/dist/types/module.nbc';
import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { TargetHierarchyV1AugurSettings as TargetHierarchyAugurSettings } from 'common/dist/types/augurSettings/targetHierarchyV1AugurSettings';

export type { TargetHierarchyAugurSettings };

export type TargetHierarchyConfig = {
  taxonomy: Taxonomy[];
};
export type ConfigTargetHierarchyConfig = Config<TargetHierarchyConfig>;

export type PartialTargetHierarchyAugurSettings =
  Partial<TargetHierarchyAugurSettings>;

export const configAllowedInputsTargetHierarchy: AllowedInputs<TargetHierarchyConfig> =
  {
    taxonomy: [{ type: SETTINGS_ELEMENT_TYPES.TARGET_TAXONOMY, version: 'v1' }],
  };

export const configTypeTargetHierarchy = `{
    taxonomy: { source: 'input-element', elementUuid: string } | 
        { source: 'hard-coded', value: Taxonomy[] }
}


type Taxonomy = {
  id: string;
  name: string;
  sub: Taxonomy[];
}`;

export const defaultConfigTargetHierarchy: ConfigTargetHierarchyConfig = {
  taxonomy: { source: 'input-element', elementUuid: '' },
};

export const augurSettingsTypeTargetHierarchy = `type Taxonomy = {
    id: string;
    name: string;
    sub: Taxonomy[];
  }

  settings = Taxonomy[]`;

export const defaultAugurSettingsDefaultTargetHierarchy: PartialTargetHierarchyAugurSettings =
  [];

// JSONSchemaType<TargetHierarchyAugurSettings>
export const defaultValidationSchemaTargetHierarchy = {
  type: 'string[]',
};
