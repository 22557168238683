import React, { FC } from 'react';
import styles from './styles.module.scss';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import { SPEAKING_OPERATOR } from '../../campaign-optimization-constraints/v1/common';
import { SPEAKING_CONTACT_POLICY_TYPE } from './common';
import classNames from 'classnames';
import { FiMinus } from 'react-icons/fi';
import { ContactPoliciesValueType } from 'common/dist/types/module.optimization';
import { ContactPoliciesErrorType } from './CampaignOptimizationContactPolicies';
import { DeepPartial } from 'react-hook-form';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { ContactPolicy } from 'common/dist/types/module.optimization';

type Option = { label: string; value: string };

export type Props = {
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: ContactPolicy) => void;
  value: ContactPoliciesValueType;
  onChange?: ((...event: any[]) => void);
  error?: DeepPartial<ContactPoliciesErrorType>;
  onBlur?: React.FocusEventHandler;

};

const contactPolicyTypeOptions: Option[] = ['count'].map((c) => ({
  // @ts-ignore
  label: SPEAKING_CONTACT_POLICY_TYPE[c],
  value: c,
}));

const operatorOptions: Option[] = ['leq', 'geq', 'eq'].map((c) => ({
  // @ts-ignore
  label: SPEAKING_OPERATOR[c],
  value: c,
}));

const drvNewValue = (
  value: ContactPoliciesValueType,
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...value.slice(0, rowIndex),
    {
      ...(value[rowIndex] || {}),
      [key]: eventValue,
    },
    ...value.slice(rowIndex + 1),
  ];
};

const renderNameField = (props: Props) => {
  const { rowIndex = 0, constraintId, value, onChange, onBlur, error } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.name}
      invalid={!!error?.rows?.[constraintId]?.name}
      label={'Name'}
      placeholder={'Name'}
      value={value[rowIndex].name}
      onChange={(e) => {
        const newValue = drvNewValue(value, e.target.value, 'name', rowIndex);
        onChange?.(newValue);
      }}
      onBlur={onBlur}
    />
  );
};

const renderDescriptionField = (props: Props) => {
  const { rowIndex = 0, constraintId, value, onChange, onBlur, error } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.description}
      invalid={!!error?.rows?.[constraintId]?.description}
      label={'Description'}
      placeholder={'Description'}
      value={value[rowIndex].description}
      onChange={(e) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...(value[rowIndex] || {}),

            description: e.target.value,
          },
          ...value.slice(rowIndex + 1),
        ];
        onChange?.(newValue);
      }}
      onBlur={onBlur}
    />

  );
};

const renderContactPolicyTypeField = (props: Props) => {
  const { rowIndex = 0, constraintId, value, onChange, onBlur, error } = props;
  return (
    <DropdownSelectInput<Option, false>
      id={'optContactPolicy_contactPolicyTypeField'}
      label={'Contact Policy Type'}
      placeholder={'Please select the Contact Policy Type'}
      options={contactPolicyTypeOptions}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        //@ts-ignore
        const newValue: ContactPoliciesValueType = [
          ...value.slice(0, rowIndex),
          {
            ...(value[rowIndex] || {}),
            contactPolicyType: option.value,
          },
          ...value.slice(rowIndex + 1),
        ];
        onChange?.(newValue);
      }}
      value={contactPolicyTypeOptions.find(
        (o) => o.value === value[rowIndex].contactPolicyType
      )}
      error={error?.rows?.[constraintId]?.contactPolicyType}
      isTouched={true}
    />

  );
};

const renderOperatorField = (props: Props) => {
  const { rowIndex = 0, constraintId, value, onChange, onBlur, error } = props;
  return (
    <DropdownSelectInput<Option, false>
    id={'optContactPolicy_operator'}
      label={'Operator'}
      placeholder={'Please select the Operator'}
      options={operatorOptions}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        //@ts-ignore
        const newValue: ContactPoliciesValueType = [
          ...value.slice(0, rowIndex),
          {
            ...(value[rowIndex] || {}),
            operator: option.value,
          },
          ...value.slice(rowIndex + 1),
        ];
        onChange?.(newValue);
      }}
      value={operatorOptions.find(
        (o) => o.value === value[rowIndex].operator
      )}
      error={error?.rows?.[constraintId]?.operator}
      isTouched={true}
    />
  );
};

const renderValueField = (props: Props) => {
  const { rowIndex = 0, constraintId, value, onChange, onBlur, error } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.value}
      invalid={!!error?.rows?.[constraintId]?.value}
      label={'Value'}
      placeholder={'1'}
      value={value[rowIndex].value}
      onChange={(e) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...(value[rowIndex] || {}),

            value: e.target.value,
          },
          ...value.slice(rowIndex + 1),
        ];
        onChange?.(newValue);
      }}
      onBlur={onBlur}
    />
  );
};

const AddConstraint: FC<Props> = (props) => {
  const { rowIndex = 0, removableRows, onRemoveRow, value } = props;

  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(value[rowIndex])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderContactPolicyTypeField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderOperatorField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderValueField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddConstraint;
