import React, { FC, useEffect, useState } from 'react';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import InputError from '../../../../../atoms/input-error/InputError';
import CommunicationsTable from './CommunicationsTable';
import {
  Communication,
} from 'common/dist/types/module.optimization';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FiPlus } from 'react-icons/fi';
import { AugurSettingsProps } from '../../types/meta';
import { CampaignOptimizationCommunicationsAugurSettings, CampaignOptimizationCommunicationsConfig, CampaignOptimizationCommunicationsValidationError } from './type';

export type Props = AugurSettingsProps<
  CampaignOptimizationCommunicationsAugurSettings,
  CampaignOptimizationCommunicationsConfig,
  CampaignOptimizationCommunicationsValidationError
>;

export type CommunicationsErrorType = {
  global: string;
  rows: {
    [constraintId: string]: {
      name?: string;
      description?: string;
      channelId?: string;
      propensityId?: string;
      boostFactor?: string;
      variableCosts?: string;
      fixedCosts?: string;
    }
  }
}

const CampaignOptimizationCommunications: FC<Props> = (props) => {
  const { config, value = [], onChange, invalid, onBlur, error } = props; 
  const { channels = [], propensities = [] } = config;

  const [addedNewRow, setAddedNewRow] = useState(false);

  const handleChange = (updatedCommunications: Communication[]) => {
    onChange?.(updatedCommunications);
  }

  // Update communications if the config changes
  useEffect(() => {
    const updatedCommunications = value.map(communication => {
      const updatedCommunication = { ...communication };

      // Update communication if necessary
      if (!channels.find(channel => channel.id === communication.channelId)) {
        updatedCommunication.channelId = '';
      }
      if (!propensities.find(propensity => propensity.id === communication.propensityId)) {
        updatedCommunication.propensityId = '';
      }

      return updatedCommunication;
    });

    handleChange(updatedCommunications);
  }, [channels, propensities]);

  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        {error?.global && (
          <InputError
            touched={true}
            error={error.global}
          />
        )}
      </div>

      <div className={commonStyles.tableContainer}>
        <CommunicationsTable
          channels={channels || []}
          propensities={propensities || []}
          addedNewRow={addedNewRow}
          rowIndex={value ? value.length - 1 : 0}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          invalid={invalid}
          error={error}
        />
      </div>
      
      <div className={commonStyles.AddIconContainer}>
        <div
          className={commonStyles.AddIcon}
          onClick={() => {
            handleChange([...value, { id: uuidv4(), name: '', channelId: '', propensityId: '', boostFactor: '' }]);
            setAddedNewRow(true);
          }}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
};

export default CampaignOptimizationCommunications;
