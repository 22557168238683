import { K8sResources } from 'common/dist/types/job';

export const toK8sResourcesSelectFormState = (
  value?: K8sResources
): K8sResources => {
  return {
    cpuRequest: value?.cpuRequest ?? '',
    cpuLimit: value?.cpuLimit ?? '',
    memoryRequest: value?.memoryRequest ?? '',
    memoryLimit: value?.memoryLimit ?? '',
    gpuRequest: value?.gpuRequest ?? '',
    gpuLimit: value?.gpuLimit ?? '',
    gpuProduct: value?.gpuProduct ?? {
      model: '',
    },
    useGpu: value?.useGpu || false,
  };
};

export const fromK8sResourcesSelectFormState = (
  value: K8sResources
): K8sResources | undefined => {
  const result: K8sResources = {};

  if (value.cpuRequest.length > 0) result.cpuRequest = value.cpuRequest;
  if (value.cpuLimit.length > 0) result.cpuLimit = value.cpuLimit;
  if (value.memoryRequest.length > 0)
    result.memoryRequest = value.memoryRequest;
  if (value.memoryLimit.length > 0) result.memoryLimit = value.memoryLimit;
  if (value.gpuRequest.length > 0) result.gpuRequest = value.gpuRequest;
  if (value.gpuLimit.length > 0) result.gpuLimit = value.gpuLimit;
  if (value.gpuProduct.model) result.gpuProduct = value.gpuProduct;
  if (value.useGpu) result.useGpu = value.useGpu;

  return Object.keys(result).length > 0 ? result : undefined;
};
