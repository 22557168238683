import { createReducer } from 'redux-act';

import { RootActions } from './actions';
import { adminBackupsReducer } from './admin/backups/reducer';
import { adminMaintenanceReducer } from './admin/maintenance/reducer';
import { adminResourcesReducer } from './admin/resources/reducer';
import { auditLogReducer } from './auditLog/slice';
import { augurBiographyReducer } from './augurBiography/slice';
import { augurBiographySidebarReducer } from './augurBiographySidebar/slice';
import { jobGroupsReducer } from './jobGroups/slice';
import { realtimeAugurReducer } from './realtime/slice';
import { runMassPredictionModalReducer } from './runMassPredictionModal/slice';
import * as states from './state';
import { DeprecatedRootState } from './state.type';
import { workbenchTerminalsReducer } from './workbenchTerminals/slice';
import { multiReportReducer } from '../components/pages/augur/utils/multiReportWrapper.state';
import * as accountAvatarModule from '../redux/modules/account.avatar.module';
import * as accountLoginHistory from '../redux/modules/account.loginHistory.module';
import * as adminUsers from '../redux/modules/admin.users.module';
import * as appsModule from '../redux/modules/apps.module';
import * as augursModule from '../redux/modules/augurs.module';
import * as authModule from '../redux/modules/auth.module';
import * as codeCapsulesModule from '../redux/modules/codeCapsules.module';
import * as config from '../redux/modules/config.module';
import * as dashboardInit from '../redux/modules/dashboard.init.module';
import * as dashboardModule from '../redux/modules/dashboard.module';
import * as dashboardSearchModule from '../redux/modules/dashboard.search.module';
import * as summaryModule from '../redux/modules/dashboard.summary.module';
import * as dataCassandraModule from '../redux/modules/data.cassandra.module';
import * as dataSourcesModule from '../redux/modules/data.dataSources.module';
import * as dataS3Module from '../redux/modules/data.s3.module';
import * as dataImportColumnModule from '../redux/modules/dataImport.column.module';
import * as dataImportColumnTypeModule from '../redux/modules/dataImport.columnType.module';
import * as dataImportModule from '../redux/modules/dataImport.module';
import * as habitatModifyModule from '../redux/modules/habitat.modify.module';
import * as mainTabs from '../redux/modules/mainTab.module';
import * as menuModule from '../redux/modules/menu.module';
import * as notificationsModule from '../redux/modules/notifications.module';
import * as codeCapsuleReportModule from '../redux/modules/orchestration.codeCapsuleReport.module';
import * as orchestrationHistory from '../redux/modules/orchestration.history.module';
import * as orchestrationJobConfigs from '../redux/modules/orchestration.jobconfigs.module';
import * as orchstrationJobDetails from '../redux/modules/orchestration.jobdetails.module';
import * as orchestrationNames from '../redux/modules/orchestration.names.module';
import * as orchestrationJobQueue from '../redux/modules/orchestration.queue.module';
import * as orchstrationSchedules from '../redux/modules/orchestration.schedules.module';
import * as orchestrationTab from '../redux/modules/orchestration.tab.module';
import * as pageModule from '../redux/modules/page.module';
import * as repoCheckNameExistsModule from '../redux/modules/repo.checkNameExists.module';
import * as userModule from '../redux/modules/user.module';

// DEPRECATED! Use the  typedReducer instead
export default createReducer(
  {
    ...authModule.reducer,
    // ...newAugurModule.reducer,
    ...summaryModule.reducer,
    ...dashboardModule.reducer,
    ...menuModule.reducer,
    ...habitatModifyModule.reducer,
    ...dashboardSearchModule.reducer,
    ...userModule.reducer,
    ...notificationsModule.reducer,
    ...pageModule.reducer,
    ...accountAvatarModule.reducer,
    ...augursModule.reducer,
    ...adminUsers.reducer,
    ...accountLoginHistory.reducer,
    ...dashboardInit.reducer,
    ...dataImportColumnModule.reducer,
    ...dataImportColumnTypeModule.reducer,
    ...dataImportModule.reducer,
    ...orchestrationTab.reducer,
    ...orchestrationJobQueue.reducer,
    ...orchestrationJobConfigs.reducer,
    ...orchestrationHistory.reducer,
    ...orchstrationSchedules.reducer,
    ...orchestrationNames.reducer,
    ...orchstrationJobDetails.reducer,
    ...mainTabs.reducer,
    ...config.reducer,
    // ---
    ...codeCapsulesModule.reducer,
    ...codeCapsuleReportModule.reducer,
    ...appsModule.reducer,
    ...dataSourcesModule.reducer,
    ...dataCassandraModule.reducer,
    ...dataS3Module.reducer,
    ...repoCheckNameExistsModule.reducer,
  },
  states.initial
);

// --- MIGRATED TO TYPESCRIPT
// Can't use new RootState, because then it would circularly reference itself
export const typedReducer = (
  state: DeprecatedRootState,
  action: RootActions
) => ({
  // @ts-ignore
  adminResources: adminResourcesReducer(state, action),
  // @ts-ignore
  adminBackups: adminBackupsReducer(state, action),
  // @ts-ignore
  adminMaintenance: adminMaintenanceReducer(state, action),
  augurBiography: augurBiographyReducer(state.augurBiography, action),
  augurBiographySidebar: augurBiographySidebarReducer(
    state.augurBiographySidebar,
    action
  ),
  jobGroups: jobGroupsReducer(state.jobGroups, action),
  runMassPredictionModal: runMassPredictionModalReducer(
    state.runMassPredictionModal,
    action
  ),
  auditLog: auditLogReducer(state.auditLog, action),
  workbenchTerminals: workbenchTerminalsReducer(
    state.workbenchTerminals,
    action
  ),
  realtimeAugur: realtimeAugurReducer(state.realtimeAugur, action),
  timeline: multiReportReducer(state.timeline, action),
});
