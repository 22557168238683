import { datapoolName } from 'common/dist/constants/dataManagement/s3';
import msgsAugursDetails from 'common/dist/messages/augurs.details';
import React, { FC, Fragment, useState } from 'react';
import { FiSliders } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import ErrorModal from './ErrorModal';
import { useAugur } from '../../../../core/api/augurs';
import { AugurDetailsRouteParams } from '../../../index/routes';
import { orchestrationRoutes } from '../../../orchestration/routes';
import ActionButton from '../action-button/ActionButton';
import ActionButtonLoading from '../action-button/ActionButtonLoading';
import styles from '../styles.module.scss';

type Props = {
  triggerLearningJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  triggerEvaluationJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
  triggerPredictionJob: (
    habitatCode: string,
    augurCode: string,
    augurName: string
  ) => void;
};

const actionButtons = {
  learning: {
    gridColumn: 4,
    gridRow: 1,
    Icon: () => <span className={'icon-play'} />,
    label: msgsAugursDetails.jobRunLearning,
  },
  evaluation: {
    gridColumn: 4,
    gridRow: 2,
    Icon: () => <span className={'icon-play'} />,
    label: msgsAugursDetails.jobRunEvaluation,
  },
  prediction: {
    gridColumn: 5,
    gridRow: 1,
    Icon: () => <span className={'icon-play'} />,
    label: msgsAugursDetails.jobRunPrediction,
  },
  toDatapool: {
    gridColumn: 6,
    gridRow: 1,
    Icon: () => <span className={'icon-datapool'} />,
    label: msgsAugursDetails.augurDataGoto,
  },
  toRealtime: {
    gridColumn: 6,
    gridRow: 2,
    Icon: () => <FiSliders />,
    label: msgsAugursDetails.realtimeGoto,
  },
};

const ActionButtons: FC<Props> = (props: Props) => {
  let { habitatCode, augurCode } = useParams<AugurDetailsRouteParams>();

  const { data: augur, isSuccess } = useAugur(habitatCode, augurCode);

  const { triggerLearningJob, triggerEvaluationJob, triggerPredictionJob } =
    props;

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  if (!isSuccess)
    return (
      <Fragment>
        {Object.values(actionButtons).map((ab, i) => (
          <ActionButtonLoading key={i} {...ab} />
        ))}
      </Fragment>
    );

  augurCode = augur.code ?? augurCode;
  habitatCode = augur.habitatCode ?? habitatCode;
  const augurName = augur.name;

  // Further enhance the action buttons with the information that came with the loaded information now
  const actionButtonsLoaded = {
    learning: {
      onClick: () => triggerLearningJob(habitatCode, augurCode, augurName),
      disabled:
        !augur?.moduleVersion.config.generalConfiguration.supportsLearning,
    },
    evaluation: {
      onClick: () => triggerEvaluationJob(habitatCode, augurCode, augurName),
      disabled:
        !augur?.moduleVersion.config.generalConfiguration.supportsEvaluation,
    },
    prediction: {
      onClick: () => triggerPredictionJob(habitatCode, augurCode, augurName),
      disabled:
        !augur?.moduleVersion.config.generalConfiguration.supportsPrediction,
    },
    toDatapool: {
      onClick: () => {},
      hasLink: true,
      disabled: !augur,
      linkTo: `/app/data/s3/${
        augur?.datapool.dataSourceCode
      }/bucket/${datapoolName(augur?.code)}/path/`,
    },
    toRealtime: {
      onClick: () => {},
      hasLink: true,
      disabled:
        !augur?.moduleVersion.config.generalConfiguration
          .supportsRealtimePrediction,
      linkTo: `${orchestrationRoutes.basePath}/${orchestrationRoutes.realtime.path}/${orchestrationRoutes.realtime.details}/${augur.code}`,
    },
  };

  return (
    <Fragment>
      {Object.keys(actionButtons).map((abId) => {
        const ab = actionButtons[abId];
        const abc = actionButtonsLoaded[abId];
        return <ActionButton key={abId} {...ab} {...abc} />;
      })}

      {augur.error && (
        <ActionButton
          gridColumn={5}
          gridRow={2}
          onClick={() => setErrorModalOpen(true)}
          Icon={() => <span className={'icon-alert'} />}
          label={{
            id: 'action.show_error',
            defaultMessage: 'Show error message',
          }}
          addlClassName={styles.actionButtonError}
        />
      )}

      {augur.error && (
        <ErrorModal
          isOpen={errorModalOpen}
          error={augur.error}
          closeModal={() => setErrorModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default ActionButtons;
