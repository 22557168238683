import classNames from 'classnames';
import {
  AugurSettingsDataOpt,
  Constraint,
  ConstraintDetails,
  EnrichedConstraint,
} from 'common/dist/types/module.optimization';
import React, { FC, useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import {
  FiLayers,
  FiMaximize,
  FiMessageSquare,
  FiPhoneForwarded,
} from 'react-icons/fi';

import AddConstraint from './AddConstraint';
import { ConstraintsErrorType } from './CampaignOptimizationConstraints';
import { SPEAKING_CONSTRAINT_TYPE, SPEAKING_OPERATOR } from './common';
import { getConstraintDetails } from './helpers';
import styles from './styles.module.scss';
import Table, { RenderColumn } from '../../../../../molecules/table/Table';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';

type Props = {
  augurSettings: Pick<
    AugurSettingsDataOpt,
    'channels' | 'communications' | 'communicationGroups'
  >;
  editMode?: boolean;
  addedNewRow?: boolean;
  rowIndex: number;
  value: Constraint[];
  onChange?: (constraint: Constraint[]) => void;
  invalid?: boolean;
  onBlur?: React.FocusEventHandler;
  error?: DeepPartial<ConstraintsErrorType>;
};

const ConstraintTable: FC<Props> = (props) => {
  const {
    augurSettings,
    addedNewRow,
    rowIndex,
    value,
    onChange,
    onBlur,
    invalid,
    error,
  } = props;

  const constraints: Constraint[] = value;

  const enrichedConstraints: EnrichedConstraint[] = (constraints).map(
    (constraint: Constraint) => ({
      ...constraint,
      icon: constraint.constraintLevel,
      constraintDetails: getConstraintDetails(constraint, augurSettings),
    })
  );
  const initialVisible = {
    index: addedNewRow ? constraints?.length - 1 : undefined,
    shown: addedNewRow ? addedNewRow : false,
  };
  const [visible, setShow] = useState(initialVisible);

  useEffect(() => {
    if (addedNewRow || rowIndex) {
      setShow({
        index: addedNewRow ? rowIndex : constraints?.length - 1,
        shown: addedNewRow ? addedNewRow : false,
      });
    }
  }, [addedNewRow, rowIndex]);

  const renderColumns: RenderColumn<
    EnrichedConstraint,
    keyof EnrichedConstraint
  >[] = [
    {
      key: 'icon',
      // @ts-ignore
      renderHeader: () => null,
      // @ts-ignore
      renderCell: (constraintLevel: EnrichedConstraint['icon']) => {
        switch (constraintLevel) {
          case 'channel':
            return (
              <div className={styles.columnIcon}>
                <FiPhoneForwarded size={16} />
              </div>
            );
          case 'communication':
            return (
              <div className={styles.columnIcon}>
                <FiMessageSquare size={16} />
              </div>
            );
          case 'communicationGroup':
            return (
              <div className={styles.columnIcon}>
                <FiLayers size={16} />
              </div>
            );
          case 'allCommunications':
            return (
              <div className={styles.columnIcon}>
                <FiMaximize size={16} />
              </div>
            );
          default:
            return null;
        }
      },
      width: '20px',
    },
    {
      key: 'name',
      renderHeader: () => <span>Name</span>,
      // @ts-ignore
      renderCell: (name: string, constraint: Constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[constraint.id]?.name,
          })}
        >
          {name}
        </span>
      ),
    },
    {
      key: 'description',
      renderHeader: () => <span>Description</span>,
      // @ts-ignore
      renderCell: (description?: string, constraint: Constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[constraint.id]?.description,
          })}
        >
          {description}
        </span>
      ),
    },
    {
      key: 'constraintDetails',
      renderHeader: () => <span>Constraint Level</span>,
      // @ts-ignore
      renderCell: (constraintDetails: EnrichedConstraint[ConstraintDetails], constraint: Constraint) => (
        <div className={styles.columnConstraintLevel}>
          <span
            className={classNames(styles.constraintReferenceName, {
              [styles.erroneous]:
                !!error?.rows?.[constraint?.id]?.channelId ||
                !!error?.rows?.[constraint?.id]?.communicationGroupId ||
                !!error?.rows?.[constraint?.id]?.communicationId,
            })}
          >
            {constraintDetails?.name}
          </span>
          <span
            className={classNames(styles.speakingLevel, {
              [styles.erroneous]:
                !!error?.rows?.[constraint?.id]?.constraintLevel ||
                !!error?.rows?.[constraint?.id]?.channelId ||
                !!error?.rows?.[constraint?.id]?.communicationGroupId ||
                !!error?.rows?.[constraint?.id]?.communicationId,
            })}
          >
            {constraintDetails?.speakingLevel}
          </span>
        </div>
      ),
    },
    {
      key: 'constraintType',
      renderHeader: () => <span>Constraint Type</span>,
      // @ts-ignore
      renderCell: (
        constraintType: EnrichedConstraint['constraintType'],
        constraint: Constraint
      ) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[constraint.id]?.constraintType,
          })}
        >
          {SPEAKING_CONSTRAINT_TYPE[constraintType]}
        </span>
      ),
    },
    {
      key: 'operator',
      renderHeader: () => <span>Operator</span>,
      // @ts-ignore
      renderCell: (operator: 'leq' | 'geq' | 'eq', constraint: Constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[constraint.id]?.operator,
          })}
        >
          {SPEAKING_OPERATOR[operator]}
        </span>
      ),
    },
    {
      key: 'value',
      renderHeader: () => <span>Value</span>,
      // @ts-ignore
      renderCell: (value: string, constraint: Constraint) => (
        <span
          className={classNames({
            [styles.erroneous]: !!error?.rows?.[constraint.id]?.value,
          })}
        >
          {value}
        </span>
      ),
    },
  ];

  const renderClickedRow = (
    element: EnrichedConstraint,
    rowIndex: number,
    fallbackFn: JSX.Element
  ) => {
    if (visible.index === rowIndex && visible.shown) {
      return (
        <tr key={rowIndex}>
          <td className={commonStyles.tdNoPadding} colSpan={8}>
            <div className={commonStyles.addContainer}>
              <AddConstraint
                rowIndex={rowIndex}
                constraintId={element.id}
                augurSettings={augurSettings}
                removableRows={visible.shown}
                onRemoveRow={(removedRow) => {
                  const updatedConstraints = constraints.filter(
                    (con) => con.id !== removedRow.id
                  );
                  onChange?.(updatedConstraints);
                }} // @ts-ignore
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                invalid={invalid}
                error={error}
              />
            </div>
          </td>
        </tr>
      );
    } else return fallbackFn;
  };

  return (
    <Table
      data={enrichedConstraints}
      renderColumns={renderColumns}
      showHeader
      removableRows={true}
      verticalAlignMiddle
      onRemoveRow={(removedRow) => {
        const updatedConstraints = constraints.filter(
          (con) => con.id !== removedRow.id
        );
        onChange?.(updatedConstraints);
      }}
      addlRowClassname={(_rowIndex: number, constraint: EnrichedConstraint) =>
        classNames(commonStyles.row, {
          [styles.erroneousRow]: !!error?.rows?.[constraint.id],
        })
      }
      renderClickedRow={renderClickedRow}
      onRowClick={(_row, rowIndex) => {
        setShow({
          index: rowIndex,
          shown: true,
        });
      }}
    />
  );
};

export default ConstraintTable;
