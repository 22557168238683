import { AugurSettingsDataOpt, Constraint } from 'common/dist/types/module.optimization';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import ConstraintsTable from './ConstraintsTable';
import {
  CampaignOptimizationConstraintsAugurSettings,
  CampaignOptimizationConstraintsConfig,
  CampaignOptimizationConstraintsValidationError,
} from './type';
import InputError from '../../../../../atoms/input-error/InputError';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import { AugurSettingsProps } from '../../types/meta';

export type Props = AugurSettingsProps<
  CampaignOptimizationConstraintsAugurSettings,
  CampaignOptimizationConstraintsConfig,
  CampaignOptimizationConstraintsValidationError
>;

export type ConstraintsErrorType = {
  global: string;
  rows: {
    [constraintId: string]: {
      name?: string;
      description?: string;
      constraintType?: string;
      operator?: string;
      value?: string;
      constraintLevel?: string;
      channelId?: string;
      communicationId?: string;
      communicationGroupId?: string;
    };
  };
};

const CampaignOptimizationConstraints: FC<Props> = (props) => {
  const { config, value = [], onChange, error, invalid, onBlur } = props;
  const {
    channels = [],
    communications = [],
    communicationGroups = [],
  } = config;

  const [addedNewRow, setAddedNewRow] = useState(false);

  const augurSettings: Pick<
    AugurSettingsDataOpt,
    'channels' | 'communications' | 'communicationGroups'
  > = {
    channels,
    communications,
    communicationGroups,
  };

  useEffect(() => {
    const updatedValue: Constraint[] = value.map((constraint: Constraint) => {
      const updatedConstraint: Constraint = { ...constraint };
      if (
        constraint.constraintLevel === 'channel' &&
        updatedConstraint.constraintLevel === 'channel' &&
        !channels.some((channel) => channel.id === constraint.channelId)
      ) {
        updatedConstraint.channelId = '';
      } else if (
        constraint.constraintLevel === 'communication' &&
        updatedConstraint.constraintLevel === 'communication' &&
        !communications.some(
          (communication) => communication.id === constraint.communicationId
        )
      ) {
        updatedConstraint.communicationId = '';
      } else if (
        constraint.constraintLevel === 'communicationGroup' &&
        updatedConstraint.constraintLevel === 'communicationGroup' &&
        !communicationGroups.some(
          (group) => group.id === constraint.communicationGroupId
        )
      ) {
        updatedConstraint.communicationGroupId = '';
      }
      return updatedConstraint;
    });
    onChange?.(updatedValue);
  }, [channels, communications, communicationGroups]);

  const expandAutomatically = () => {
    const isEmpty = _.isEmpty(value);
    if (isEmpty && !addedNewRow) {
      setAddedNewRow(true);
      onChange?.([...value, { id: uuidv4() }]);
    }
  };

  expandAutomatically();

  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        {error?.global && <InputError touched={true} error={error.global} />}
      </div>
      <div className={commonStyles.tableContainer}>
        <ConstraintsTable
          editMode={true}
          augurSettings={augurSettings}
          addedNewRow={addedNewRow}
          rowIndex={value.length - 1}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          invalid={invalid}
          error={error}
        />
      </div>

      <div className={commonStyles.AddIconContainer}>
        <div
          className={commonStyles.AddIcon}
          onClick={() => {
            onChange?.([...value, { id: uuidv4() }]);
            setAddedNewRow(true);
          }}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
};

export default CampaignOptimizationConstraints;
