import React, { FC, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';

import commonStyles from './commonStyles.module.scss';
import TupleTable from './TupleTable';
import InputError from '../../atoms/input-error/InputError';

export type TableRow = { id: string; value1?: string; value2?: string };

export type TupleListTableError = {
  global: string;
  rows: {
    [channelId: string]: [string, string];
  };
};

export type Props = {
  label1: string;
  placeholder1: string;
  label2: string;
  placeholder2: string;
  data: TableRow[];
  onChange: (updatedRows: TableRow[]) => void;
  isTouched?: boolean;
  errors?: DeepPartial<TupleListTableError>;
  invalid?: boolean;
  onBlur?: React.FocusEventHandler;
};

const TupleListTable: FC<Props> = (props) => {
  const {
    label1,
    placeholder1,
    label2,
    placeholder2,
    data,
    onChange,
    isTouched,
    errors,
    invalid,
    onBlur,
  } = props;

  const [addedNewRow, setAddedNewRow] = useState(false);

  return (
    <div className={commonStyles.optComponent}>
      <div className={commonStyles.errorContainer}>
        <InputError touched={isTouched} error={errors?.global} />
      </div>

      <div className={commonStyles.tableContainer}>
        <TupleTable
          data={data}
          setData={onChange}
          isTouched={isTouched}
          invalid={invalid}
          onBlur={onBlur}
          label1={label1}
          placeholder1={placeholder1}
          label2={label2}
          errors={errors}
          placeholder2={placeholder2}
          addedNewRow={addedNewRow}
          rowIndex={data.length - 1 || 0}
        />
      </div>

      <div className={commonStyles.AddIconContainer}>
        <div
          className={commonStyles.AddIcon}
          onClick={() => {
            onChange([...data, { id: uuidv4() }]); //Add new table row to table data
            setAddedNewRow(true);
          }}
        >
          <FiPlus size={18} />
        </div>
      </div>
    </div>
  );
};

export default TupleListTable;
