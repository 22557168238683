import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { deriveElements } from './utils';
import {
  useAugurs,
  useUpdateAugurWithAugurCode,
} from '../../../../core/api/augurs';
import { useProgressSummary } from '../../../../core/api/orchestration';
import { comparators } from '../../../../core/dashboard';
import { getOrder } from '../../../../redux/selectors/augurs.selectors';
import { isListView as getIsListView } from '../../../../redux/selectors/dashboard.selector';
import { RootState } from '../../../../store/store';
import CollapsibleArea from '../../../molecules/collapsable-area/CollapsibleArea';
import ElementCardGrid from '../../../organisms/element-card-grid/ElementCardGrid';
import ElementCardTable from '../../../organisms/element-card-table/ElementCardTable';
import NoElements from '../no-elements/NoElements';

type Props = {
  /** Habitat Code */
  habitatCode: string;
};

enum TABS {
  ACTIVE,
  ARCHIVE,
}

export const HABITAT_GRID_MAX_HEIGHT = 300;

const Augurs: FC<Props> = (props) => {
  const { habitatCode } = props;
  const intl = useIntl();
  const [tab, setTab] = useState<TABS>(TABS.ACTIVE);

  const progressQuery = useProgressSummary(true);
  const jobProgressSummary = progressQuery.isSuccess ? progressQuery.data : {};

  const { data: augursData } = useAugurs(habitatCode, false);
  const augurs = augursData ?? [];
  const { data: archivedAugursData } = useAugurs(habitatCode, true);
  const archivedAugurs = archivedAugursData ?? [];

  // Fix the habitatCode and payload here, so that we get a function we can use where we know the augurCode but can't use hooks
  const { mutate: updateAugur } = useUpdateAugurWithAugurCode(habitatCode);
  const restoreAugur = (augurCode: string) =>
    updateAugur({ augurCode, payload: { archived: false } });

  const order = useSelector<RootState, string>((state) =>
    getOrder(state, { habitatCode })
  );
  const comparator = comparators[order];

  const sortedAugurs = augurs.sort(comparator);
  const sortedArchivedAugurs = archivedAugurs.sort(comparator);

  const isListView = useSelector<RootState, boolean>((state) =>
    getIsListView(state, habitatCode)
  );

  const getElements = () => {
    if (tab === TABS.ARCHIVE) {
      // --- Archive tab is opened
      return deriveElements(
        sortedArchivedAugurs,
        habitatCode,
        intl,
        true,
        restoreAugur,
        archivedAugurs.length,
        () => setTab(TABS.ARCHIVE),
        () => setTab(TABS.ACTIVE)
      );
    } else {
      // --- Active augurs tab is opened
      return deriveElements(
        sortedAugurs,
        habitatCode,
        intl,
        false,
        restoreAugur,
        archivedAugurs.length,
        () => setTab(TABS.ARCHIVE),
        () => setTab(TABS.ACTIVE),
        jobProgressSummary
      );
    }
  };
  const elements = getElements();

  if (!augurs || (augurs.length === 0 && archivedAugurs.length === 0))
    return (
      <NoElements
        title={{
          id: 'dashboard.augurs.add_first_augur',
          defaultMessage: 'There are no Augurs for this Habitat yet.',
        }}
      />
    );

  return (
    <div className={'augurs'}>
      <CollapsibleArea
        maxHeight={HABITAT_GRID_MAX_HEIGHT}
        contentStyle={{
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: 0,
        }}
        childrenStyle={{
          paddingBottom: '20px',
        }}
      >
        {isListView ? (
          <ElementCardTable elements={elements} />
        ) : (
          <ElementCardGrid elements={elements} />
        )}
      </CollapsibleArea>
    </div>
  );
};

export default Augurs;
